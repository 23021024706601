export const StorageItems = {
    ActorType: "ActorType",
    Token: "Tk",
    FinancerMemberId: "financierMemberId",
    ExecutorMemberId: "executorMemberId"
}

export const ActorTypes = {
    Financier: "Financier",
    Executor: "Executor"
}