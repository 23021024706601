import { Box, Paper, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { tokens } from "../../theme";
import CreateFinancierForm from "../../components/authenticate/CreateFinancierForm";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default observer(function CreateFinancier() {
  const colors = tokens();
  let query = useQuery();
  const [referralCode, setReferralCode] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setReferralCode(query.get("referralcode") ?? undefined);
  }, []);

  return (
    <div className="login-container">
      <div className="login-container_left"></div>
      <div className="login-container_right">
        <Paper
          sx={{
            p: 5,
          }}
        >
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: `${colors.blue[300]}`,
                fontWeight: "bold",
                fontSize: "2rem",
                textAlign: "center",
              }}
            >
              Become A Financier
            </Typography>
          </Box>

          <CreateFinancierForm referralCode={referralCode} />
        </Paper>
      </div>
    </div>
  );
});
