import requests from "../main/apiConfig";
import { AccountTypeModel, CreateOrUpdateAccountTypeModel } from "../models/accountType";
import { BaseModel } from "../models/common";

export const AccountType = {
    GetAccountTypes: () => requests.getAllPaginated<BaseModel<AccountTypeModel[]>>("/AccountType/GetAccountTypes"),

    GetAccountTypeById: (id: string) => requests.get<BaseModel<AccountTypeModel>>(`/AccountType/GetAccountTypeById/${id}`),

    CreateAccountType: (payload: CreateOrUpdateAccountTypeModel) => requests.post<BaseModel<AccountTypeModel>>("/AccountType/CreateAccountType", payload),

    UpdateAccountType: (payload: CreateOrUpdateAccountTypeModel) => requests.put<BaseModel<AccountTypeModel>>("/AccountType/UpdateAccountType", payload),

    DeleteAccountType: (id: string) => requests.del<BaseModel<boolean>>(`/AccountType/DeleteAccountType/${id}`)
}