import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../../theme";
import { DateOnlyFormatter, NairaFormatter } from "../../../helpers/formatters";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { PlainOutlinedButton } from "../../shared/buttons";
import { InvestmentModel } from "../../../api/models/investment";
import ModalDecisionContent from "../../shared/modal/ModalDecisionContent";

interface Props {
  data: InvestmentModel[];
}

const columns = ["Name", "Date", "Capital", "Profit", "Maturity", "Action"];

export default observer(function ActiveInvestmentTable({ data }: Props) {
  const colors = tokens();
  const { commonStore, investmentStore } = useStore();

  const handleRedeemInvestmentClick = (investment: InvestmentModel) =>
    commonStore.setModalContent(
      <ModalDecisionContent
        actionName={`about to redeem ${NairaFormatter(
          investment.capital + investment.profit
        )}`}
        actionCallback={() => investmentStore.redeemInvestment(investment.id)}
      />,
      ""
    );

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="center">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">
                    {DateOnlyFormatter(row.dateInvested)}
                  </TableCell>
                  <TableCell align="center">
                    {NairaFormatter(row.capital)}
                  </TableCell>
                  <TableCell align="center">
                    {NairaFormatter(row.profit)}
                  </TableCell>
                  <TableCell align="center">
                    {DateOnlyFormatter(row.maturityDate)}
                  </TableCell>
                  <TableCell align="center">
                    <PlainOutlinedButton
                      title="Redeem"
                      actionCallback={() => handleRedeemInvestmentClick(row)}
                      color="success"
                      disable={!row.canRedeem}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
