import "./ModalDecisionContent.css";
import { observer } from "mobx-react-lite";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Button, Divider } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import { PlainOutlinedButton } from "../buttons";

interface Props {
  actionName: string;
  actionCallback: () => void;
  futherDetail?: ReactJSXElement;
}

export default observer(function ModalDecisionContent({
  actionName,
  actionCallback,
  futherDetail,
}: Props) {
  const { commonStore } = useStore();

  return (
    <div>
      <div className="modal-decision-text">You are about to {actionName}</div>
      {futherDetail ? <Divider /> : null}

      <div className="">{futherDetail}</div>
      {futherDetail ? <Divider /> : null}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 4,
        }}
      >
        <PlainOutlinedButton
          title="Confirm"
          actionCallback={actionCallback}
          color="success"
        />
        <PlainOutlinedButton
          title="Cancel"
          actionCallback={() => commonStore.setModalVisible(false)}
          color="error"
        />
      </Box>
    </div>
  );
});
