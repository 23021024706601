import { Box } from "@mui/material";
import { tokens } from "../../theme";
import TestimonialCard from "./TestimonialCard";

export default function Testimonial() {
  const colors = tokens();

  return (
    <div className="testimonial-container">
      <Box>
        <Box
          sx={{
            width: "40px",
          }}
        >
          <img
            src="./assets/quote-mark.png"
            style={{
              width: "60px",
              marginBottom: "-2rem",
              marginLeft: "-2rem",
            }}
          />
        </Box>
        <h4>
          Real Stories from <br />
          financiers like{" "}
          <span
            style={{
              color: `${colors.blue[300]}`,
              fontWeight: "bold",
            }}
          >
            YOU
          </span>
        </h4>
      </Box>

      <div className="testimonial-cards">
        <TestimonialCard
          name="Amanda"
          date="February 2024"
          content="I used to own a truck for logistics business, which was hard to manage. It's amazing to be able to finance logistics projects without having to worry about managing a truck and driver!"
        />
        <TestimonialCard
          name="Rachael"
          date="January 2024"
          content="For me, the Logisfi interface is user-friendly right from onboarding. So far, financing real haulage projects on this platform has been quite transparent and rewarding."
        />
        <TestimonialCard
          name="Daniel"
          date="February 2024"
          content="I am quite impressed with how straightforward and secured it is to finance projects on Logisfi, and I would highly recommend."
        />
      </div>
    </div>
  );
}
