import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  imageName: string;
  content: string;
}

export default function ChooseUsCard({ title, imageName, content }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        alignItems: "center",
      }}
    >
      <Box>
        <img
          src={`./assets/${imageName}.png`}
          style={{
            width: "4rem",
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            mb: 1,
            textTransform: "capitalize",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            textAlign: "justify",
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
}
