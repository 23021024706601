import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FinancierMemberModel } from "../../../api/models/financier";
import { useStore } from "../../../api/main/appStore";
import MyFormikController from "../../shared/forms/MyFormikController";

interface Props {
  financier: FinancierMemberModel;
}

export default observer(function Refer({ financier }: Props) {
  const initialValues = {
    referralCode: financier.referralCode,
    referralLink: financier.referralLink,
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => console.log(values)}
      >
        {({ values }) => (
          <Form>
            <Box>
              <MyFormikController
                control="copy"
                label="Referral Code"
                name="referralCode"
                valueToCopy={values.referralCode}
                copyResponse="Referral code copied to clipboard"
              />
              <MyFormikController
                control="copy"
                label="Referral Link"
                name="referralLink"
                valueToCopy={values.referralLink}
                copyResponse="Referral link copied to clipboard"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
