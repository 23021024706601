import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import NumberTemplate from "./NumberTemplate";
import { NumberToK } from "../../helpers/formatters";

interface Props {
  projectsCreated: number;
  projectsFinanced: number;
  investmentCreated: number;
}

export default function Numbers({
  projectsCreated,
  projectsFinanced,
  investmentCreated,
}: Props) {
  const colors = tokens();

  return (
    <div className="numbers-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          color: `${colors.grey[100]}`,
          pt: 4,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Some numbers that matter
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "0.85rem",
            }}
          >
            Our achievement in the journey depicted in numbers
          </Typography>
        </Box>
        <div className="numbers-cards">
          <NumberTemplate
            value={NumberToK(projectsCreated)}
            title="Projects Created"
          />
          <NumberTemplate
            value={NumberToK(projectsFinanced)}
            title="Project Financed"
          />
          <NumberTemplate
            value={NumberToK(investmentCreated)}
            title="Investments Created"
          />
        </div>
      </Box>
    </div>
  );
}
