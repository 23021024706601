import axios, { AxiosResponse } from "axios";
import { backendUrl } from "../../urls";
import { store } from "./appStore";

axios.defaults.baseURL = backendUrl;

axios.interceptors.request.use((request) => {
  request.headers.set("API_KEY", process.env.REACT_APP_VITRUS_CERELEM)
  // request.headers.Authorization = `Bearer ${process.env.REACT_APP_TOKEN}`

  const token = store.commonStore.token;
  if (token) request.headers.Authorization = `Bearer ${token}`;

  return request;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const responseBodyPagination = <T>(response: AxiosResponse<T>) => {
  return { data: response.data, headers: response.headers }
};

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),

  getAllPaginated: <T>(url: string) => axios.get<T>(url).then(responseBodyPagination),

  post: <T>(url: string, body?: {}) =>
    axios.post<T>(url, body).then(responseBody),

  put: <T>(url: string, body?: {}) =>
    axios.put<T>(url, body).then(responseBody),

  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

export default requests;
