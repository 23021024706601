import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";
import SuccessPaymentTable from "../tables/SuccessPaymentTable";

export default observer(function SuccessfulPayments() {
  const { paymentStore } = useStore();

  useEffect(() => {
    (async () => {
      await paymentStore.getPayments({
        status: "success",
        pageNumber: 1,
        pageSize: 100,
      });
    })();
  }, []);

  return (
    <Box>
      <SuccessPaymentTable data={paymentStore.successPayments} />
    </Box>
  );
});
