import { Box, Paper, Typography } from "@mui/material";
import { tokens } from "../../theme";
import DimmingAnimation from "./DimmingAnimation";
import { observer } from "mobx-react-lite";
import LiveProjectsTable from "./LiveProjectsTable";
import { Circle } from "@mui/icons-material";
import DimmingElement from "../shared/animated/DimmingElement";
import { ProjectModel } from "../../api/models/project";

interface Props {
  data: ProjectModel[];
}

export default observer(function LiveProjects({ data }: Props) {
  const colors = tokens();

  return (
    <div className="live-projects-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
          p: 4,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.3rem",
            }}
          >
            Explore financing options for a diverse range of aggregated loads
          </Typography>

          <DimmingAnimation
            strings={["Flour", "Sugar", "Beverages", "Biscuit"]}
            intervalDuration={3000}
            dimDuration={1000}
          />
        </Box>

        <Paper
          sx={{
            p: 2,
            width: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <DimmingElement
                element={
                  <Circle
                    sx={{
                      width: "0.8rem",
                      color: `${colors.green[500]}`,
                    }}
                  />
                }
                intervalDuration={2000}
                dimDuration={1000}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                Live Projects
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <LiveProjectsTable data={data} />
        </Paper>
      </Box>
    </div>
  );
});
