import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";
import StatCard from "../../financier/overview/StatCard";
import InvestmentNearMatureTable from "../tables/InvestmentNearMatureTable";

export default observer(function ExecutorOverview() {
  const { commonStore, executorStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        await executorStore.getExecutorOverviewData();
      } catch (error) {
        commonStore.handleAppError(error);
      }
    })();
  }, []);

  const overviewData = executorStore.overviewData;

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <StatCard
          title="Registered Financiers"
          value={
            overviewData ? `${overviewData.totalRegisteredFinanciers}` : "0"
          }
        />
        <StatCard
          title="ActiveProjects"
          value={overviewData ? `${overviewData.totalActiveProjects}` : "0"}
        />
        <StatCard
          title="Pending Withdrawals"
          value={overviewData ? `${overviewData.totalPendingWithdrawals}` : "0"}
        />
      </Box>

      <Divider
        sx={{
          my: 2,
        }}
      />

      <Box>
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          Investments near maturity
        </Typography>
        <InvestmentNearMatureTable
          data={overviewData ? overviewData.nearMaturityInvestments : []}
        />
      </Box>
    </Box>
  );
});
