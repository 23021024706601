import { string } from "yup";
import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CreateOrUpdateInvestmentTenureModel, InvestmentTenureModel } from "../models/investmentTenure";

export const InvestmentTenure = {
    GetAllAsync: () => requests.getAllPaginated<BaseModel<InvestmentTenureModel[]>>("/InvestmentTenure/GetAllAsync"),

    GetAsync: (id: string) => requests.get<BaseModel<InvestmentTenureModel>>(`/InvestmentTenure/GetAsync/${id}`),

    CreateAsync: (payload: CreateOrUpdateInvestmentTenureModel) => requests.post<BaseModel<InvestmentTenureModel>>("/InvestmentTenure/CreateAsync", payload),

    UpdateAsync: (payload: CreateOrUpdateInvestmentTenureModel) => requests.put<BaseModel<InvestmentTenureModel>>("/InvestmentTenure/UpdateAsync", payload),

    DeleteAsync: (id: string) => requests.del<BaseModel<boolean>>(`/InvestmentTenure/DeleteAsync/${id}`)
}