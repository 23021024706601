import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";

interface Props {
  strings: string[];
  intervalDuration: number;
  dimDuration: number;
}

export default function DimmingAnimation({
  strings,
  intervalDuration,
  dimDuration,
}: Props) {
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const colors = tokens();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpacity(0); // Reset opacity for the next string

      setTimeout(() => {
        setCurrentStringIndex((prevIndex) => (prevIndex + 1) % strings.length);
        setOpacity(1); // Dim the string
      }, dimDuration);
    }, intervalDuration);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [strings, intervalDuration, dimDuration]);

  return (
    <div style={{ opacity, transition: `opacity ${dimDuration}ms ease` }}>
      <Typography
        sx={{
          fontSize: "3.2rem",
          color: `${colors.blue[300]}`,
          fontWeight: "bold",
        }}
      >
        {strings[currentStringIndex]}
      </Typography>
    </div>
  );
}
