import { Box } from "@mui/material";
import Header from "../../components/shared/header/Header";
import HorizontalTabs from "../../components/shared/horizontal-tab";
import { observer } from "mobx-react-lite";
import ExecutorSharedView from "../shared/ExecutorSharedView";
import CreateUser from "../../components/executor/users/CreateUser";
import ActiveUsers from "../../components/executor/users/ActiveUsers";

export default observer(function Users() {
  return (
    <ExecutorSharedView
      mainContent={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Users Manager" />
          </Box>

          <HorizontalTabs
            tabNames={["Active", "Create"]}
            tabContents={[<ActiveUsers />, <CreateUser />]}
          />
        </Box>
      }
    />
  );
});
