import { makeAutoObservable, runInAction } from 'mobx'
import { ChangePasswordModel, ExecutorUserModel, FinancierUserModel, ForgotPasswordModel, LoginModel } from '../models/authentication'
import agent from '../main/apiAgent'
import { store } from '../main/appStore'
import { OtherRoutes, ROUTES, ROUTES1 } from '../../routes'
import { ActorTypes, StorageItems } from '../../data/staticData'

export class AuthenticationStore {

    constructor() {
        makeAutoObservable(this)
    }

    forgotPasswordFinancier = async (values: ForgotPasswordModel) => {
        let navigateUrl = "";
        try {
            store.commonStore.setLoading(true)

            const result = await agent.authentication.FinancierForgotPassword(values)

            store.commonStore.setAlertText("Please enter the one-time-password sent to your email")

            navigateUrl = OtherRoutes.validateOtp
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return navigateUrl
    }

    forgotPasswordExecutor = async (values: ForgotPasswordModel) => {
        let navigateUrl = "";
        try {
            store.commonStore.setLoading(true)

            const result = await agent.authentication.ExecutorForgotPassword(values)

            store.commonStore.setAlertText("An OTP has been sent to your registered email")

            navigateUrl = OtherRoutes.validateOtp
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return navigateUrl
    }

    validateOtp = async (otp: string) => {
        try {
            store.commonStore.setLoading(true)

            const result = await agent.authentication.ValidateOtp(otp)

            return result.responseDetails;
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    changePasswordFinancier = async (values: ChangePasswordModel) => {
        let location = ""
        try {
            store.commonStore.setLoading(true)

            localStorage.clear()
            const result = await agent.authentication.ChangeFinancierPassword(values);

            const details = result.responseDetails;

            runInAction(() => {
                store.financierStore.currentFinancier = details.user
                store.commonStore.setToken(details.token)
                localStorage.setItem(StorageItems.FinancerMemberId, details.user.financierId)
                localStorage.setItem(StorageItems.Token, details.token)
                localStorage.setItem(StorageItems.ActorType, ActorTypes.Financier)
            })
            location = ROUTES1.financier

        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }

    changePasswordExecutor = async (values: ChangePasswordModel) => {
        let location = ""
        try {
            store.commonStore.setLoading(true)

            localStorage.clear()
            const result = await agent.authentication.ChangeExecutorPassword(values);

            const details = result.responseDetails;

            runInAction(() => {
                store.executorStore.currentExecutor = details.user
                store.commonStore.setToken(details.token)
                localStorage.setItem(StorageItems.ExecutorMemberId, details.user.executorId)
                localStorage.setItem(StorageItems.Token, details.token)
                localStorage.setItem(StorageItems.ActorType, ActorTypes.Executor)
            })
            location = ROUTES.executor

        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }
}