import { Box, Divider, Paper, Typography } from "@mui/material";
import Header from "../../shared/header/Header";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";
import TransactionsTable from "../tables/TransactionsTable";
import { NairaFormatter } from "../../../helpers/formatters";
import { tokens } from "../../../theme";
import { ThickFillButtonLong } from "../../shared/buttons";
import MySkeleton from "../../shared/loading-spinner/MySkeleton";
import { useNavigate } from "react-router-dom";
import { ROUTES1 } from "../../../routes";
import FundWallet from "./FundWallet";
import WithdrawToBank from "./WithdrawToBank";

export default observer(function Wallets() {
  const { commonStore, investmentStore, financierStore } = useStore();
  const navigate = useNavigate();
  const colors = tokens();

  const handleFinanceNewProjectsClick = () => navigate(ROUTES1.projects);

  const handleFundWalletClick = () =>
    commonStore.setModalContent(<FundWallet />, "");

  const handleWithdrawFundClick = () =>
    commonStore.setModalContent(<WithdrawToBank />, "");

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);
        if (financierStore.currentFinancier) {
          await investmentStore.getTransactions({
            financierId: financierStore.currentFinancier.financierId,
            pageNumber: 1,
            pageSize: 100,
          });

          await financierStore.getFinancierWallet(
            financierStore.currentFinancier.financierId
          );
        }
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  const wallet = financierStore.myWallet;

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 5,
          minHeight: "30vh",
          flexWrap: "wrap",
        }}
      >
        <Paper
          sx={{
            p: 1,
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
            }}
          >
            Wallet Balance
          </Typography>

          <Box
            sx={{
              my: 3,
              color: colors.grey[500],
              fontSize: "1.4rem",
              textAlign: "center",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            {wallet ? NairaFormatter(wallet.balance) : <MySkeleton count={1} />}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <ThickFillButtonLong
              title="Fund Wallet"
              actionCallback={handleFundWalletClick}
              color="primary"
            />

            <ThickFillButtonLong
              title="Withdraw to Bank"
              actionCallback={handleWithdrawFundClick}
              color="success"
            />
          </Box>
        </Paper>
        <Paper
          sx={{
            p: 1,
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
            }}
          >
            Expected Returns
          </Typography>
          <Box
            sx={{
              my: 3,
              color: colors.grey[500],
              fontSize: "1.4rem",
              textAlign: "center",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            {wallet ? (
              NairaFormatter(wallet.expectedProfitSum)
            ) : (
              <MySkeleton count={1} />
            )}
          </Box>

          <Box>
            <ThickFillButtonLong
              title="Finance New Projects"
              actionCallback={handleFinanceNewProjectsClick}
              color="primary"
            />
          </Box>
        </Paper>
      </Box>

      <Paper
        sx={{
          mt: 3,
          p: 1,
          height: "50vh",
          overflow: "auto",
        }}
      >
        <Header title="Transaction History" />
        <Divider
          sx={{
            display: "block",
            width: "10%",
            borderBottomWidth: "0.2rem",
            backgroundColor: "#005ac1",
          }}
        />

        <Box sx={{}}>
          <TransactionsTable data={investmentStore.transactions} />
        </Box>
      </Paper>
    </Box>
  );
});
