import { observer } from "mobx-react-lite";
import { Box, Button, CircularProgress } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import { ProjectTypeModel } from "../../../api/models/projectType";

interface Props {
  projectType: ProjectTypeModel | null;
}

export default observer(function CreateOrUpdateProjectType({
  projectType,
}: Props) {
  const { projectTypeStore } = useStore();

  const initialValues = {
    id: projectType?.id ?? "",
    name: projectType?.name ?? "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Project type name is required"),
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          projectType
            ? projectTypeStore.updateProjectType(values)
            : projectTypeStore.createProjectType(values)
        }
      >
        {({ values, isSubmitting }) => (
          <Form>
            <MyFormikController
              control="input"
              label="Enter Project Type"
              name="name"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" />
              ) : projectType ? (
                "Modify"
              ) : (
                "Proceed"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
