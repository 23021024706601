import { observer } from "mobx-react-lite";
import { Box, Button, Typography } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import { HeaderSecondary } from "../../shared/header/Header";
import { NairaFormatter } from "../../../helpers/formatters";

export default observer(function FundWallet() {
  const { paymentStore } = useStore();

  const initialValues = {
    amount: "",
  };

  const validationSchema = Yup.object({
    amount: Yup.number()
      .typeError("Enter valid digits")
      .positive("Enter positive digit")
      .required("Amount is required"),
  });

  return (
    <Box>
      <HeaderSecondary title={`Fund Wallet`} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => paymentStore.initializeWalletFunding(values)}
      >
        {({ values }) => (
          <Form>
            <MyFormikController
              control="input"
              label={
                values.amount
                  ? `You've entered ${
                      Number.isNaN(+values.amount)
                        ? "an invalid amount"
                        : NairaFormatter(+values.amount)
                    }`
                  : "Funding Amount"
              }
              placeholder="Enter funding amount"
              name="amount"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Proceed
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
