import { observer } from "mobx-react-lite";
import Footer from "../../components/landing/Footer";
import { Box } from "@mui/material";
import Testimonial from "../../components/landing/Testimonial";
import "../../components/landing/landing.css";
import ChooseUs from "../../components/landing/ChooseUs";
import Numbers from "../../components/landing/Numbers";
import HowItWork from "../../components/landing/HowItWork";
import ProjectSources from "../../components/landing/ProjectSources";
import LiveProjects from "../../components/landing/LiveProjects";
import { useStore } from "../../api/main/appStore";
import { useEffect } from "react";

export default observer(function Landing() {
  const { commonStore, executorStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        if (!executorStore.homeData) {
          await executorStore.getHomeData();
        }
      } catch (error) {
        commonStore.handleAppError(error);
      }
    })();
  }, []);

  const homeData = executorStore.homeData;

  return (
    <Box>
      <LiveProjects data={homeData ? homeData.topProjects : []} />
      <ProjectSources />
      <HowItWork />
      <Numbers
        projectsCreated={homeData ? homeData.projectsCreated : 0}
        projectsFinanced={homeData ? homeData.projectsFinanced : 0}
        investmentCreated={homeData ? homeData.investmentCreated : 0}
      />
      <ChooseUs />
      <Testimonial />
      <Footer />
    </Box>
  );
});
