import requests from "../main/apiConfig";
import { AuthenticationResponseModel, ExecutorUserModel, LoginModel } from "../models/authentication";
import { BaseModel, ChangeDefaultPasswordModel } from "../models/common";
import { ChangeExecutorStateModel, CreateOrUpdateExecutorMemberModel, CreateOrUpdateExecutorModel, ExecutorMemberModel, ExecutorModel, ExecutorOverviewModel, HomeDataModel } from "../models/executor";

export const Executor = {
    GetExecutors: () => requests.getAllPaginated<BaseModel<ExecutorModel[]>>("/Executor/GetExecutors"),

    GetExecutorById: (id: string) => requests.get<BaseModel<ExecutorModel>>(`/Executor/GetExecutorById/${id}`),

    CreateExecutor: (payload: CreateOrUpdateExecutorModel) => requests.post<BaseModel<ExecutorModel>>("/Executor/CreateExecutor", payload),

    UpdateExecutor: (payload: CreateOrUpdateExecutorModel) => requests.put<BaseModel<ExecutorModel>>("/Executor/UpdateExecutor", payload),

    ChangeExecutorState: (payload: ChangeExecutorStateModel) => requests.post<BaseModel<ChangeExecutorStateModel>>("/Executor/ChangeExecutorState", payload),

    GetExecutorMembers: (executorId: string) => requests.getAllPaginated<BaseModel<ExecutorMemberModel[]>>(`/ExecutorMember/GetExecutorMembers?ExecutorId=${executorId}`),

    GetExecutorMemberById: (id: string) => requests.get<BaseModel<ExecutorMemberModel>>(`/ExecutorMember/GetExecutorMemberById/${id}`),

    CreateExecutorMember: (payload: CreateOrUpdateExecutorMemberModel) => requests.post<BaseModel<ExecutorMemberModel>>("/ExecutorMember/CreateExecutorMember", payload),

    UpdateExecutorMember: (payload: CreateOrUpdateExecutorMemberModel) => requests.put<BaseModel<ExecutorMemberModel>>("/ExecutorMember/UpdateExecutorMember", payload),

    ChangeDefaultPassword: (payload: ChangeDefaultPasswordModel) => requests.post<BaseModel<ExecutorMemberModel>>("/ExecutorMember/ChangeDefaultPassword", payload),

    Login: (payload: LoginModel) => requests.post<BaseModel<AuthenticationResponseModel<ExecutorUserModel>>>("/Authentication/ExecutorLogin", payload),

    GetExecutorOverview: () => requests.get<BaseModel<ExecutorOverviewModel>>("/Executor/GetExecutorOverview"),

    GetHomeData: () => requests.get<BaseModel<HomeDataModel>>("/Executor/GetHomeData")
}