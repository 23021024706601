import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const NairaFormatter = (value: number) => {
  const result = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "NGN",
  }).format(value);

  return result.replace("NGN", "₦");
};

export const DateOnlyFormatter = (value: string) =>
  dayjs(value).format("DD/MM/YYYY");

export const NumberToHourMin = (value: number) => {
  const hr = Math.floor(value / 60);
  const min = (value - 60 * hr).toString().padStart(2, "0");

  return `${hr}h ${min}m`;
};

export const DayAndMonthFormatter = (value: string) =>
  dayjs(value).format("MMMM DD");

export const CompleteDateFormatter = (value: string) =>
  dayjs(value).format("MMMM DD, YYYY");

export const IsDaySame = (value1: string, value2: string) =>
  dayjs(value1).isSame(value2, "day");

export const dateFromNow = (value: string) => dayjs(value).fromNow();

export const DateAndTimeFormat = (value: string) =>
  dayjs(value).format("DD/MM/YY hh:mm:ss A");

export const DecimalToPercent = (value: number) => (value * 100).toFixed(2) + "%"

export const NameFromCreatedBy = (value: string) => value.split('-')[0] ?? "No name"

export const NumberToK = (num: number) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}