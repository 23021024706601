import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";

export default observer(function CreateUser() {
  const { commonStore, executorStore } = useStore();

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    role: "",
    executorId: executorStore.currentExecutor!.executorId,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
    phoneNumber: Yup.number()
      .required("Phone number is required")
      .typeError("Must be a valid digits"),
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await executorStore.createExecutorMember({ ...values });
          resetForm();
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <MyFormikController
              control="input"
              label="First name"
              placeholder="Enter user first name"
              name="firstName"
            />
            <MyFormikController
              control="input"
              label="Last name"
              placeholder="Enter user first name"
              name="lastName"
            />
            <MyFormikController
              control="input"
              label="Email"
              placeholder="Enter user email"
              name="email"
              type="email"
            />
            <MyFormikController
              control="input"
              label="Phone number"
              placeholder="Enter user phone number"
              name="phoneNumber"
            />
            <MyFormikController
              control="select"
              label="Role"
              placeholder="Select user role"
              name="role"
              options={executorStore.roleOptions}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
