import { makeAutoObservable, runInAction } from 'mobx'
import { CreateOrUpdateProductTypeModel, ProductTypeModel } from '../models/productType'
import agent from '../main/apiAgent'
import { store } from '../main/appStore'
import { SelectOptionModel } from '../models/common'

export class ProductTypeStore {
    productTypes: ProductTypeModel[] = []
    productTypesOptions: SelectOptionModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getProductTypes = async () => {
        try {
            const result = await agent.productType.GetAllAsync()

            runInAction(() => {
                this.productTypes = result.data.responseDetails
            })
        } catch (error) {
            throw error
        }
    }

    getProductTypes2 = async () => {
        try {
            const result = await agent.productType.GetAllAsync()

            runInAction(() => {
                this.productTypesOptions = result.data.responseDetails.map((el) => {
                    return {
                        text: el.name,
                        value: el.name
                    }
                })
            })
        } catch (error) {
            throw error
        }
    }

    createProductType = async (values: CreateOrUpdateProductTypeModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)
            const result = await agent.productType.CreateAsync(values)

            store.commonStore.setAlertText(`${values.name} created successfully!`)

            this.getProductTypes()

            return result.responseDetails

        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    updateProductType = async (values: CreateOrUpdateProductTypeModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.productType.UpdateAsync(values)

            store.commonStore.setAlertText(`${values.name} updated successfully!`)

            this.getProductTypes()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    deleteProductType = async (productTypeId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.productType.DeleteAsync(productTypeId)

            store.commonStore.setAlertText("Product type deleted successfully!")

            this.getProductTypes()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }
}