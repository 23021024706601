import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../api/main/appStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VerifiedOutlined } from "@mui/icons-material";
import { tokens } from "../../../theme";
import { ROUTES1 } from "../../../routes";

export default observer(function VerifyPaymentContent() {
  const { commonStore, paymentStore } = useStore();
  const navigate = useNavigate();
  const searchParam = useSearchParams();
  const colors = tokens();

  let renderCount = 0;

  useEffect(() => {
    if (renderCount > 0) {
      return;
    }
    (async () => {
      try {
        renderCount++;
        const reference = searchParam[0].get("trxref");
        if (reference) {
          await paymentStore.verifyWalletFunding(reference);

          commonStore.setAlertText("Wallet funding was successful!");
        }
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        navigate(ROUTES1.wallet, { replace: true });
      }
    })();
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        height: "30rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box>
        <VerifiedOutlined
          sx={{
            fontSize: "5rem",
            color: `${colors.yellow[300]}`,
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: "2px",
            color: `${colors.grey[500]}`,
          }}
        >
          Verifying Payment...
        </Typography>
      </Box>
    </Box>
  );
});
