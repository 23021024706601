import { Box } from "@mui/material";
import Header from "../../components/shared/header/Header";
import HorizontalTabs from "../../components/shared/horizontal-tab";
import UnapprovedProjectsTable from "../../components/executor/tables/UnapprovedProjectsTable";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../api/main/appStore";
import PendingFinanciersTable from "../../components/executor/tables/PendingFinanciersTable";
import ExecutorSharedView from "../shared/ExecutorSharedView";

export default observer(function ExecutorApprovals() {
  const { projectStore, commonStore, financierStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);

        await projectStore.getProjects({
          isApproved: false,
          isClosed: false,
          pageNumber: 1,
          pageSize: 60,
        });

        await financierStore.getFinanciers({
          isActive: false,
          pageNumber: 1,
          pageSize: 60,
        });
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  return (
    <ExecutorSharedView
      mainContent={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Approval Desk" />
          </Box>

          <HorizontalTabs
            tabNames={["projects", "financier"]}
            tabContents={[
              <UnapprovedProjectsTable
                data={projectStore.unapprovedProjects}
              />,
              <PendingFinanciersTable
                data={financierStore.pendingFinanciers}
              />,
            ]}
          />
        </Box>
      }
    />
  );
});
