import { Box, Paper, Typography, colors } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../theme";
import { useStore } from "../../api/main/appStore";
import { observer } from "mobx-react-lite";
import FinancierLoginForm from "./FinancierLoginForm";
import ExecutorLoginForm from "./ExecutorLoginForm";

interface ItemProps {
  title: string;
  selected: string;
  setSelected: Function;
}

const Item = ({ title, selected, setSelected }: ItemProps) => {
  const colors = tokens();

  const active = selected === title;

  return (
    <Paper
      sx={{
        px: 2,
        backgroundColor: `${active ? "#fff" : "inherit"}`,
        borderRadius: `${active ? "5px" : "0px"}`,
        cursor: "pointer",
        width: "100%",
        textAlign: "center",
      }}
      elevation={active ? 1 : 0}
      onClick={() => {
        if (active) {
          return;
        } else {
          setSelected(title);
        }
      }}
    >
      <Typography
        sx={{
          color: `${colors.blue[300]}`,
          fontSize: "1rem",
        }}
      >
        {title}
      </Typography>
    </Paper>
  );
};

export default observer(function ActorTab() {
  const { commonStore } = useStore();

  const [selected, setSelected] = useState(
    commonStore.getLocalStorageStringItem("ActorType", "Financier")
  );
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: `${colors.blue[100]}`,
            px: 0.5,
            py: 0.5,
            borderRadius: "5px",
            minWidth: "40%",
            display: "flex",
          }}
        >
          <Item
            title="Financier"
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Executor"
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </Box>

      <Box>
        {selected === "Financier" ? (
          <FinancierLoginForm />
        ) : (
          <ExecutorLoginForm />
        )}
      </Box>
    </Box>
  );
});
