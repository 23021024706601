import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { useEffect } from "react";
import RedeemedInvestmentTable from "../tables/RedeemedInvestmentTable";

export default observer(function RedeemedInvestments() {
  const { commonStore, investmentStore, financierStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);

        if (financierStore.currentFinancier) {
          await investmentStore.getInvestments({
            financierId: financierStore.currentFinancier.financierId,
            isRedeemed: true,
            pageNumber: 1,
            pageSize: 100,
          });
        }
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, [commonStore, financierStore.currentFinancier, investmentStore]);

  return (
    <Box>
      <RedeemedInvestmentTable data={investmentStore.redeemedInvestments} />
    </Box>
  );
});
