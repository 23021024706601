import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CreateInvestmentModel, InvestmentModel, TransactionModel } from "../models/investment";

export const Investment = {
    Create: (payload: CreateInvestmentModel) => requests.post<BaseModel<InvestmentModel>>("/Investments/CreateInvestment", payload),

    GetInvestments: (query: string) => requests.getAllPaginated<BaseModel<InvestmentModel[]>>(`/Investments/GetInvestments?${query}`),

    RedeemInvestment: (investmentId: string) => requests.post<BaseModel<string>>(`/Investments/RedeemInvestment/${investmentId}`),

    GetTransactions: (query: string) => requests.getAllPaginated<BaseModel<TransactionModel[]>>(`/Investments/Transactions?${query}`)
}