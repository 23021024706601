import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import StatCard from "./StatCard";
import { NairaFormatter } from "../../../helpers/formatters";
import LiveProjectsTable from "../../landing/LiveProjectsTable";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";

export default observer(function FinancierOverview() {
  const { commonStore, financierStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        await financierStore.getFinancierOverviewData();
      } catch (error) {
        commonStore.handleAppError(error);
      }
    })();
  }, []);

  const overviewData = financierStore.overviewData;

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <StatCard
          title="Trucks Financed"
          value={overviewData ? `${overviewData.totalProjectsFinanced}` : "0"}
        />
        <StatCard
          title="Profit Earned"
          value={
            overviewData ? NairaFormatter(overviewData.totalProfitEarned) : "0"
          }
        />
        <StatCard
          title="Expected Profit"
          value={
            overviewData
              ? NairaFormatter(overviewData.totalProfitExpected)
              : "0"
          }
        />
      </Box>

      <Divider
        sx={{
          my: 2,
        }}
      />

      <Box>
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          Top Projects by ROI %
        </Typography>
        <LiveProjectsTable
          data={overviewData ? overviewData.topProjects : []}
        />
      </Box>
    </Box>
  );
});
