import { observer } from 'mobx-react-lite'
import WhoWeAre from './WhoWeAre'
import { motion } from 'framer-motion'
import AboutUsDetails from './AboutUsDetails'
export default observer(function AboutUs() {
  return (
    <>
      <div className='about-us-container'>
        <motion.h1
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
            ease: 'linear'
          }}
        >
          About Us
        </motion.h1>
        <motion.p
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          Welcome to Logisfi – Empowering Africa’s Logistics with Financial
          Innovation
        </motion.p>
      </div>
      <WhoWeAre />
      {/* <AboutUsDetails /> */}

      <section className='about-us-details'>
        <motion.div
          style={{ marginBottom: '40px' }}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h3>Our Story</h3>
          <p>
            Developed by a team of experts in logistics and technology, Logisfi
            emerged from the realization that the African logistics market
            harbors untapped potential hindered by fragmented financial support
            systems. Based in Nigeria, we are at the heart of Africa’s bustling
            trade and commerce. At Logisfi, we bridge the gap between logistics
            operations and financial institutions, creating a symbiotic
            ecosystem where both sectors thrive. The platform is envisioned as a
            digital ecosystem that seamlessly connects financiers to aggregated
            loads in need of funds for their transportation.
          </p>
        </motion.div>
        <motion.div
          style={{ marginBottom: '40px' }}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
          viewport={{ once: true }}
        >
          <h3>What we do</h3>
          <p>
            Logisfi provides a state-of-the-art platform that integrates
            cutting-edge technology with innovative business models tailored
            specifically for the African market. Our solutions cater to:
          </p>
          <ul className='solution-list'>
            <li>
              Institutional and Individual Investors: Offering secure platform
              for aggregated loads in need of financial support to transport
              them.
            </li>
            <li>
              {' '}
              Logistics Companies: Facilitating easier access to financing,
              enabling them to expand operations, increase efficiency, and
              reduce costs.
            </li>
          </ul>
        </motion.div>

        <motion.div
          style={{ marginBottom: '40px' }}
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
          viewport={{ once: true }}
        >
          <h3>Our Technology</h3>
          <p>
            Security and innovation are at the core of our platform. We employ
            advanced encryption and real-time data analytics to ensure that
            every transaction is transparent, secure, and efficient. Our
            API-driven approach allows for seamless integration with existing
            systems, making our platform as flexible as it is powerful.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.6 }}
          style={{ marginBottom: '40px' }}
          viewport={{ once: true, amount: 0.8 }}
        >
          <h3>Our Impact</h3>
          <p>
            Logisfi isn’t just about business growth; it’s about fostering
            regional development. By improving the logistics sector's financial
            backbone, we contribute to better infrastructure, more jobs, and
            stronger economies across Africa.
          </p>
        </motion.div>
      </section>
      <section className='culture'>
        <figure>
          <div className='image-container'>
            <img src='./assets/conviction.png' alt='conviction icon' />
            <figcaption>
              <h3>Conviction</h3>
            </figcaption>
          </div>
          <p>
            We pride ourselves in consistently providing our clients with
            confidence and peace of mind, in an ever-changing and multifaceted
            financial world.{' '}
          </p>
        </figure>
        <figure>
          <div className='image-container'>
            <img src='./assets/duty.png' alt='duty icon' />
            <figcaption>
              <h3>Duty of care</h3>
            </figcaption>
          </div>
          <p>
            At Logisfi, our approach separates us – putting our client interests
            first – with transparency and an investment approach based upon a
            well-rooted, long-term philosophy.
          </p>
        </figure>
        <figure>
          <div className='image-container'>
            <img src='./assets/journey.png' alt='journey icon' />
            <figcaption>
              <h3>Dynamism</h3>
            </figcaption>
          </div>
          <p>
            Our responsiveness and accessibility are integral elements of our
            signature process – helping our clients navigate their financial
            journeys alongside the changing landscape of their lives.
          </p>
        </figure>
      </section>
    </>
  )
})
