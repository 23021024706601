import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import { tokens } from "../../../theme";
import {
  DateAndTimeFormat,
  DateOnlyFormatter,
} from "../../../helpers/formatters";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import CreateOrUpdateProductType from "../productTypes/CreateOrUpdateProductType";
import ModalDecisionContent from "../../shared/modal/ModalDecisionContent";
import { ProjectSourceModel } from "../../../api/models/projectSource";
import CreateOrUpdateProjectSource from "../projectSources/CreateOrUpdateProjectSource";
import { PlainOutlinedButton } from "../../shared/buttons";

interface Props {
  data: ProjectSourceModel[];
}

const columns = [
  "Name",
  "Address",
  "About",
  "Monthly Volume",
  "Last Modified",
  " ",
];

export default observer(function ProjectSourceTable({ data }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const colors = tokens();
  const { commonStore, projectSourceStore } = useStore();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpdateProjectSourceClick = (projectSource: ProjectSourceModel) =>
    commonStore.setModalContent(
      <CreateOrUpdateProjectSource projectSource={projectSource} />,
      ""
    );

  const handleDeleteProjectSourceClick = (projectSource: ProjectSourceModel) =>
    commonStore.setModalContent(
      <ModalDecisionContent
        actionName={`delete ${projectSource.name}`}
        actionCallback={() =>
          projectSourceStore.deleteProjectSource(projectSource.id)
        }
      />,
      ""
    );

  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="left" style={{ minWidth: 140 }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* "Name", "Address", "About", "Monthly Volume", "Last Modified", */}
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell
                      sx={{
                        py: 0,
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                      }}
                    >
                      {row.address}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                      }}
                    >
                      {row.about}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 0,
                      }}
                    >
                      {row.monthlyVolume}
                    </TableCell>
                    <TableCell>{DateOnlyFormatter(row.dateModified)}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                      }}
                    >
                      <PlainOutlinedButton
                        title="Edit"
                        color="primary"
                        actionCallback={() =>
                          handleUpdateProjectSourceClick(row)
                        }
                      />
                      &nbsp;
                      <PlainOutlinedButton
                        title="Delete"
                        color="error"
                        actionCallback={() =>
                          handleDeleteProjectSourceClick(row)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
});
