import { makeAutoObservable, runInAction } from 'mobx'
import { CreateProjectModel, ProjectModel, ProjectPagination } from '../models/project'
import { store } from '../main/appStore'
import agent from '../main/apiAgent'
import { queryStringBuilder } from '../../helpers/general'

export class ProjectStore {
    unapprovedProjects: ProjectModel[] = [];
    activeProjects: ProjectModel[] = [];
    closedProjects: ProjectModel[] = [];
    selectedProject: ProjectModel | null = null;

    constructor() {
        makeAutoObservable(this)
    }

    getProjects = async (paginate: ProjectPagination) => {
        try {
            const query = queryStringBuilder(paginate);

            const result = await agent.project.GetProjects(query)

            runInAction(() => {
                if (query.includes("isApproved") && paginate.isApproved === false) {
                    this.unapprovedProjects = result.data.responseDetails
                }
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    getActiveProjects = async (paginate: ProjectPagination) => {
        try {
            const query = queryStringBuilder(paginate);

            const result = await agent.project.GetProjects(query)

            runInAction(() => {
                this.activeProjects = result.data.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    getClosedProjects = async (paginate: ProjectPagination) => {
        try {
            const query = queryStringBuilder(paginate);

            const result = await agent.project.GetProjects(query)

            runInAction(() => {
                this.closedProjects = result.data.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    getProjectById = async (projectId: string) => {
        try {
            store.commonStore.setLoading(true)
            const result = await agent.project.GetProject(projectId)

            runInAction(() => {
                this.selectedProject = result.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    createProject = async (values: CreateProjectModel) => {
        try {
            store.commonStore.setLoading(true)

            const result = await agent.project.CreateProject(values)

            store.commonStore.setAlertText("Project created successfully!")

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    approveProject = async (projectId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.project.ApproveProject(projectId)

            this.getProjects({
                isApproved: false,
                isClosed: false,
                pageNumber: 1,
                pageSize: 60,
            })

            store.commonStore.setAlertText("Project successfully approved!")

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }


    deleteProject = async (projectId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.project.DeleteProject(projectId)

            this.getProjects({
                isApproved: false,
                isClosed: false,
                pageNumber: 1,
                pageSize: 60,
            })

            store.commonStore.setAlertText("Project deleted successfully!")

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    closeProject = async (projectId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.project.CloseProject(projectId)

            store.commonStore.setAlertText(result.responseDetails)

            this.getActiveProjects({
                isApproved: true,
                isClosed: false,
                pageNumber: 1,
                pageSize: 60,
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }
}