interface Props {
  fileUrl: string;
  fileName: string;
}

const DocumentLink = ({ fileUrl, fileName }: Props) => {
  return (
    <a href={fileUrl} download={fileName}>
      {fileName}
    </a>
  );
};

export default DocumentLink;
