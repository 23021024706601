import { makeAutoObservable, runInAction } from 'mobx';
import { AccountTypeModel, CreateOrUpdateAccountTypeModel } from '../models/accountType';
import agent from '../main/apiAgent';
import { store } from '../main/appStore';

export class AccountTypeStore {
    accountTypes: AccountTypeModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getAccountTypes = async () => {
        try {
            const result = await agent.accountType.GetAccountTypes()

            runInAction(() => {
                this.accountTypes = result.data.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    createAccountType = async (values: CreateOrUpdateAccountTypeModel) => {
        try {
            const result = await agent.accountType.CreateAccountType(values)

            store.commonStore.setAlertText(`New account type ${values.name} added successfully!`)

            return result.responseDetails
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    updateAccountType = async (values: CreateOrUpdateAccountTypeModel) => {
        try {
            const result = await agent.accountType.UpdateAccountType(values)

            store.commonStore.setAlertText(`Account type ${values.name} updated successfully!`)

            return result.responseDetails
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    deleteAccountType = async (accountTypeId: string) => {
        try {
            const result = await agent.accountType.DeleteAccountType(accountTypeId)

            store.commonStore.setAlertText(`Account type deleted successfully!`)

            return
        } catch (error) {
            throw error
        }
    }
}