import { observer } from "mobx-react-lite";
import { Box, Button, CircularProgress } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import { ProjectSourceModel } from "../../../api/models/projectSource";

interface Props {
  projectSource: ProjectSourceModel | null;
}

export default observer(function CreateOrUpdateProjectSource({
  projectSource,
}: Props) {
  const { projectSourceStore } = useStore();

  const initialValues = {
    id: projectSource?.id ?? "",
    name: projectSource?.name ?? "",
    address: projectSource?.address ?? "",
    about: projectSource?.about ?? "",
    monthlyVolume: projectSource?.monthlyVolume ?? "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Source name is required"),
    address: Yup.string().required("Source address is required"),
    about: Yup.string().required("About detail is required"),
    monthlyVolume: Yup.number()
      .typeError("Enter valid digits")
      .positive("Enter positive digit")
      .required("Monthly volume is required"),
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          projectSource
            ? projectSourceStore.updateProjectSource(values)
            : projectSourceStore.createProjectSource(values)
        }
      >
        {({ values, isSubmitting }) => (
          <Form>
            <MyFormikController
              control="input"
              label="Enter source name"
              name="name"
            />
            <MyFormikController
              control="input"
              label="Enter source address"
              name="address"
            />
            <MyFormikController
              control="text-area"
              label="Enter source about"
              name="about"
            />
            <MyFormikController
              control="input"
              label="Enter source monthly volume"
              name="monthlyVolume"
              type="number"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" />
              ) : projectSource ? (
                "Modify"
              ) : (
                "Proceed"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
