import { Box, Divider } from "@mui/material";
import { tokens } from "../../theme";
import ProjectSourceTemplate from "./ProjectSourceTemplate";

export default function ProjectSources() {
  const colors = tokens();

  return (
    <div className="sources-container">
      <Box
        sx={{
          px: "5rem",
          py: "2rem",
        }}
      >
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            mt: 1,
            overflow: "auto",
          }}
        >
          <ProjectSourceTemplate imageName="http://www.thehaulagehub.com/Content/Uploads/fmn-logo.png" />
          <ProjectSourceTemplate imageName="http://www.thehaulagehub.com/Content/Uploads/ng-uacn-logo.png" />
          <ProjectSourceTemplate imageName="http://www.thehaulagehub.com/Content/Uploads/A&P%20Foods.png" />
          <ProjectSourceTemplate imageName="http://www.thehaulagehub.com/Content/Uploads/promasidor-logo.jpg" />
          <ProjectSourceTemplate imageName="http://www.thehaulagehub.com/Content/Uploads/olam-1.png" />
          <ProjectSourceTemplate imageName="http://www.thehaulagehub.com/Content/Uploads/viju-LOGO.png" />
        </Box>
        <Divider />
      </Box>
    </div>
  );
}
