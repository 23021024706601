import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import HowItWorkTemplate from "./HowItWorkTemplate";

export default function HowItWork() {
  const colors = tokens();

  return (
    <div className="works-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
          p: 4,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            How it{" "}
            <span
              style={{
                color: `${colors.blue[300]}`,
              }}
            >
              works
            </span>
          </Typography>
        </Box>
        <div className="works-cards">
          <HowItWorkTemplate
            title="Create an account"
            brief="Create an account to access your investment dashboard"
            imageName="account-step"
          />
          <HowItWorkTemplate
            title="View active projects"
            brief="Search for exciting offers from our collection of active trucking projects"
            imageName="view-step"
          />
          <HowItWorkTemplate
            title="Invest and earn"
            brief="Invest in choice offers and withdraw your earnings at maturity"
            imageName="invest-step"
          />
        </div>
      </Box>
    </div>
  );
}
