import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { AlertColor } from "@mui/material";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { AppError } from "../models/common";
import { globalNavigate } from "../../global-history";
import { OtherRoutes, ROUTES1 } from "../../routes";
import { ActorTypes, StorageItems } from "../../data/staticData";
import { store } from "../main/appStore";
import agent from "../main/apiAgent";

export class CommonStore {
    token: string | null = null;
    loading = false;
    isModalFullScreen = false;
    modalVisible = false;
    modalContent: ReactJSXElement | null = null;
    modalTitle: string = "";
    alertVisible = false;
    alertText = "";
    alertSeverity: AlertColor = "success";
    isLoggedIn = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (value: boolean) => (this.loading = value);

    setModalVisible = (value: boolean) => (this.modalVisible = value);

    setModalContent = (
        content: ReactJSXElement,
        modalTitle: string,
        isFullScreen = false
    ) => {
        window.scrollTo(0, 0);
        this.modalVisible = true;
        this.modalContent = content;
        this.modalTitle = modalTitle;
        this.setIsModalFullScreen(isFullScreen);
    };
    setIsModalFullScreen = (value: boolean) => (this.isModalFullScreen = value);

    setAlertVisible = (value: boolean) => (this.alertVisible = value);

    setAlertText = (value: string, isError = false) => {
        this.alertVisible = true;
        this.alertText = value;

        if (isError) {
            this.alertSeverity = "error";
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
            this.alertSeverity = "success";
        }
    };

    setToken = (token: string | null) => {
        this.token = token;
    };

    setIsLoggedIn = (value: boolean) => {
        this.isLoggedIn = value;
    };

    handleAppError = (error: any, data?: any) => {
        const resError = error.response as AppError;
        switch (resError.status) {
            case 401:
                this.setIsLoggedIn(false)
                localStorage.clear()
                globalNavigate(OtherRoutes.login, { replace: true })
                if (resError.data.responseCode === "41") {
                    this.setAlertText(resError.data.responseMessage, true)
                } else {
                    this.setAlertText("You are unauthorized please login!", true)
                }
                break;
            case 403:
                this.setAlertText("You're not authorized for this operation", true)
                break;
            case 412:
                globalNavigate(OtherRoutes.changeDefaultPassword, { replace: true, state: { actorType: data.actorType, email: data.email, defaultPassword: data.defaultPassword } })
                break;
            default:
                this.setAlertText(resError.data.responseMessage, true)
                if (resError.data.responseMessage.toLowerCase().startsWith("insufficient"))
                    globalNavigate(ROUTES1.wallet)
                break;
        }
    }

    handleSetUser = async () => {
        const token = localStorage.getItem(StorageItems.Token)

        if (token) {
            this.setToken(token)

            const actorType = localStorage.getItem(StorageItems.ActorType)

            if (actorType) {
                this.setIsLoggedIn(true)
                if (actorType === ActorTypes.Financier) {
                    const userId = localStorage.getItem(StorageItems.FinancerMemberId)

                    const user = await agent.financier.GetFinancierMemberById(userId!)

                    runInAction(() => {
                        store.financierStore.currentFinancier = user.responseDetails
                    })
                } else if (actorType === ActorTypes.Executor) {
                    const userId = localStorage.getItem(StorageItems.ExecutorMemberId)

                    const user = await agent.executor.GetExecutorMemberById(userId!)

                    runInAction(() => {
                        store.executorStore.currentExecutor = user.responseDetails
                    })
                }
            }
        }
    }

    generalLogout = () => {
        localStorage.clear()
        this.setToken(null)
        store.financierStore.currentFinancier = null
        store.executorStore.currentExecutor = null
        this.setIsLoggedIn(false)
    }

    getLocalStorageStringItem = (key: string, defaultValue: string) => localStorage.getItem(key) ?? defaultValue
}
