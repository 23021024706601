import { makeAutoObservable, runInAction } from 'mobx'
import { CreateOrUpdateInvestmentTenureModel, InvestmentTenureModel } from '../models/investmentTenure'
import { store } from '../main/appStore'
import agent from '../main/apiAgent'
import { SelectOptionModel } from '../models/common'

export class InvestmentTenureStore {
    investmentTenures: InvestmentTenureModel[] = []
    investmentTenuresOptions: SelectOptionModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getInvestmentTenures = async () => {
        try {
            store.commonStore.setLoading(true)

            const result = await agent.investmentTenure.GetAllAsync()

            runInAction(() => {
                this.investmentTenures = result.data.responseDetails
            })
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    getInvestmentTenures2 = async () => {
        try {
            const result = await agent.investmentTenure.GetAllAsync()

            runInAction(() => {
                this.investmentTenuresOptions = result.data.responseDetails.map((el) => {
                    return {
                        text: `${el.tenure} days`,
                        value: el.tenure
                    }
                })
            })
        } catch (error) {
            throw error
        }
    }

    createInvestmentTenure = async (values: CreateOrUpdateInvestmentTenureModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.investmentTenure.CreateAsync(values)

            store.commonStore.setAlertText("Investment tenure added successfully")

            this.getInvestmentTenures()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    updateInvestmentTenure = async (values: CreateOrUpdateInvestmentTenureModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.investmentTenure.UpdateAsync(values)

            store.commonStore.setAlertText("Investment tenure updated successfully")
            this.getInvestmentTenures()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    deleteInvestmentTenure = async (id: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.investmentTenure.DeleteAsync(id)

            store.commonStore.setAlertText("Investment tenure deleted successfully")
            this.getInvestmentTenures()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }
}