import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import { HeaderSecondary } from "../../shared/header/Header";
import { ExecutorMemberModel } from "../../../api/models/executor";

interface Props {
  user: ExecutorMemberModel;
}

export default observer(function ChangeUserRole({ user }: Props) {
  const { executorStore } = useStore();

  const initialValues = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    role: user.role,
    executorId: user.executorId,
  };

  const validationSchema = Yup.object({
    role: Yup.string().required("Role is required"),
  });

  return (
    <Box>
      <HeaderSecondary title={`Modify ${user.firstName}'s role`} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => executorStore.updateExecutorMember(values)}
      >
        {({ values }) => (
          <Form>
            <MyFormikController
              control="select"
              label="Role"
              placeholder="Select user role"
              name="role"
              options={executorStore.roleOptions}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Complete
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
