import React from 'react'

export default function JoinUs() {
  return (
    <section>
      <div className='join-us'>
        <h3>Join Us on this Journey</h3>
        <p>
          We are more than just a company; we are a movement. At Logisfi, we’re
          committed to unlocking the potential of African logistics through
          financial innovation. Join us as we pave the way for a brighter, more
          connected future.
        </p>
      </div>

      <div className='action-container'>
        <p>Let’s build tomorrow, together.</p>
        <div className='screen center'>
          <p className='action-message'>
            For partnerships, investment opportunities, or more information,
            please contact us.
          </p>
        </div>
        <a href='mailto:info@logisfi.com' className='action-btn'>
          Get in touch
        </a>
      </div>
    </section>
  )
}
