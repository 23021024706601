import { makeAutoObservable, runInAction } from "mobx";
import { ConfirmManualTransferModel, ManualTransferModel, ManualTransferPagination, UploadManualTransferModel } from "../models/manualTransfer";
import { queryStringBuilder } from "../../helpers/general";
import agent from "../main/apiAgent";
import { store } from "../main/appStore";
import { globalNavigate } from "../../global-history";
import { ROUTES1 } from "../../routes";

export class ManualTransferStore {
    confirmedTransfers: ManualTransferModel[] = [];
    unconfirmedTransfers: ManualTransferModel[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    getManualTransfers = async (paginate: ManualTransferPagination) => {
        try {
            const query = queryStringBuilder(paginate);

            const result = await agent.manualTransfer.GetAllManualTransfer(query)

            runInAction(() => {
                if (query.includes("isConfirmed") && paginate.isConfirmed === false) {
                    this.unconfirmedTransfers = result.data.responseDetails
                } else if (query.includes("isConfirmed") && paginate.isConfirmed === true) {
                    this.confirmedTransfers = result.data.responseDetails
                }
            })

        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    deleteManualTransfer = async (transferId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.manualTransfer.DeleteManualTransfer(transferId);

            this.getManualTransfers({
                isConfirmed: false,
                pageNumber: 1,
                pageSize: 60
            })

            store.commonStore.setAlertText(result.responseDetails);
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    confirmManualTransfer = async (values: ConfirmManualTransferModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.manualTransfer.ConfirmManualTransfer(values);

            this.getManualTransfers({
                isConfirmed: false,
                pageNumber: 1,
                pageSize: 60
            })

            store.commonStore.setAlertText(result.responseDetails)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    uploadManualTransfer = async (values: UploadManualTransferModel) => {
        try {
            store.commonStore.setLoading(true)

            const formData = new FormData()
            formData.append("reciept", values.reciept)
            formData.append("financierId", values.financierId)

            const result = await agent.manualTransfer.UploadManualTransfer(formData)

            store.commonStore.setAlertText(result.responseDetails)

            globalNavigate(ROUTES1.wallet)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    getCompanyAccount = async () => {
        const result = await agent.manualTransfer.GetCompanyAccount();
        return result.responseDetails
    }
}