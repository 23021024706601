import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../../api/main/appStore";
import Header, { HeaderSecondary } from "../../shared/header/Header";
import {
  CompleteDateFormatter,
  DecimalToPercent,
  NairaFormatter,
} from "../../../helpers/formatters";
import { tokens } from "../../../theme";
import { ThickFillButton } from "../../shared/buttons";
import { ProjectModel } from "../../../api/models/project";
import CreateInvestment from "../investments/CreateInvestment";

export default observer(function ProjectDetail() {
  const param = useParams();
  const { projectStore, commonStore } = useStore();
  const colors = tokens();

  useEffect(() => {
    (async () => {
      const projectId = param["id"];
      await projectStore.getProjectById(projectId!);
    })();
  }, []);

  const handleCreateInvestment = (value: ProjectModel) =>
    commonStore.setModalContent(<CreateInvestment project={value} />, "");

  if (!projectStore.selectedProject) {
    return <></>;
  }
  const project = projectStore.selectedProject;

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box>
        <Header title={project.projectSource} />
        <Divider
          sx={{
            display: "block",
            width: "10%",
            borderBottomWidth: "0.2rem",
            backgroundColor: "#005ac1",
          }}
        />
      </Box>

      <Box
        sx={{
          mt: 1,
        }}
      >
        <HeaderSecondary title="Project Description" />

        <Typography>{project.description}</Typography>
      </Box>

      <Divider
        sx={{
          my: 1,
        }}
      />

      <Box>
        <HeaderSecondary title="Investment Details" />

        <Box
          sx={{
            display: "flex",
            gap: 20,
            mt: 2,
          }}
        >
          <Box>
            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Interest Rate
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                }}
              >
                {DecimalToPercent(project.roiPercent)}
              </Typography>
            </Box>

            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Tenure
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                }}
              >
                {project.tenure} Days
              </Typography>
            </Box>

            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                No. of Trucks
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                }}
              >
                {project.totalNumOfTruck}
              </Typography>
            </Box>

            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Closing Date
              </Typography>
              <Typography
                sx={{
                  color: `${colors.red[300]}`,
                  fontSize: "0.85rem",
                }}
              >
                {CompleteDateFormatter(project.endDate)}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Funding Needed
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                }}
              >
                {NairaFormatter(project.totalAmount)}
              </Typography>
            </Box>

            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Funding Achieved
              </Typography>
              <Typography
                sx={{
                  color: `${colors.green[500]}`,
                  fontSize: "0.9rem",
                }}
              >
                {NairaFormatter(project.totalAmount - project.amountLeft)}
              </Typography>
            </Box>

            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Funding Balance
              </Typography>
              <Typography
                sx={{
                  color: `${colors.yellow[500]}`,
                  fontSize: "0.9rem",
                }}
              >
                {NairaFormatter(project.amountLeft)}
              </Typography>
            </Box>

            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Amount Per Truck
              </Typography>
              <Typography
                sx={{
                  color: `${colors.blue[300]}`,
                  fontSize: "0.9rem",
                }}
              >
                {NairaFormatter(project.amountPerUnit)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 1,
        }}
      >
        <ThickFillButton
          title="Finance Project"
          actionCallback={() => handleCreateInvestment(project)}
          color="primary"
        />
      </Box>
    </Box>
  );
});
