import { observer } from "mobx-react-lite";
import Footer from "../../components/landing/Footer";
import { Box } from "@mui/material";
import "../../components/landing/landing.css";
import AboutUs from "../../components/landing/AboutUs";
import JoinUs from "../../components/landing/JoinUs";
import { useEffect } from "react";

export default observer(function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <AboutUs />
      <JoinUs />

      <Footer />
    </Box>
  );
});
