import { FormControl, FormHelperText, TextField } from "@mui/material";
import { useField } from "formik";
import { SelectOptionModel } from "../../../api/models/common";
import { tokens } from "../../../theme";

export interface MyInputProps {
  id?: string;
  placeholder?: string;
  name: string;
  type?: string;
  label?: string;
  value?: any;
  min?: number;
  disabled?: boolean;
  checked?: boolean;
  required?: boolean;
  options?: SelectOptionModel[];
  control?: string;
  defaultValue?: any;
  valueToCopy?: string;
  copyResponse?: string;
}

export default function CustomInput(props: MyInputProps) {
  const [field, meta] = useField(props);
  const { name, label, ...rest } = props;
  const colors = tokens();

  return (
    <FormControl
      fullWidth
      sx={{
        mb: 1,
      }}
    >
      <TextField
        id={name}
        autoComplete="off"
        label={label}
        {...rest}
        error={meta.touched && meta.error ? true : false}
        color="primary"
        variant="standard"
        {...field}
        inputProps={{
          sx: {
            padding: "4px",
          },
        }}
      />
      {meta.touched && meta.error ? (
        <FormHelperText
          sx={{
            color: "red",
            p: 0,
            ml: 0,
          }}
        >
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
