import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../shared/forms/MyFormikController";
import { useStore } from "../../api/main/appStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ActorTypes } from "../../data/staticData";
import { ChangeDefaultPasswordModel } from "../../api/models/common";

export default observer(function ChangeDefaultPasswordForm() {
  const { financierStore, executorStore } = useStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [actor] = useState(state.actorType);
  const [email] = useState(state.email);
  const [defaultPassword] = useState(state.defaultPassword);

  const initialValues = {
    email,
    newPassword: "",
    defaultPassword,
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required("Password is required"),
  });

  const handleChangeDefaultPassword = async (
    values: ChangeDefaultPasswordModel
  ) => {
    let url = "";

    if (actor === ActorTypes.Financier) {
      url = await financierStore.changeDefaultPassword(values);
    } else if (actor === ActorTypes.Executor) {
      url = await executorStore.changeDefaultPassword(values);
    }

    if (url.length > 0) {
      navigate(url, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleChangeDefaultPassword(values)}
      >
        {() => (
          <Form>
            <Box>
              <MyFormikController
                control="input-password"
                label="New Password"
                placeholder="Enter your new password"
                name="newPassword"
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Done
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
