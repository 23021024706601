import { Box } from "@mui/material";
import Header from "../../shared/header/Header";
import Tag from "../../shared/tag/Tag";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { useEffect } from "react";
import ProjectsTable from "../tables/ProjectsTable";

export default observer(function Projects() {
  const { commonStore, projectStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);

        await projectStore.getActiveProjects({
          isApproved: true,
          isClosed: false,
          pageNumber: 1,
          pageSize: 100,
        });
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box>
        <Header title="Projects" />
      </Box>

      <Box
        sx={{
          mt: 3,
        }}
      >
        <ProjectsTable data={projectStore.activeProjects} />
      </Box>
    </Box>
  );
});
