import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../../theme";
import { DateOnlyFormatter, NairaFormatter } from "../../../helpers/formatters";
import { observer } from "mobx-react-lite";
import { PaymentModel } from "../../../api/models/payment";

interface Props {
  data: PaymentModel[];
}

const columns = ["Financier", "Email", "Amount", "Channel", "Date"];

export default observer(function SuccessPaymentTable({ data }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const colors = tokens();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="left" style={{ maxWidth: 100 }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const userDetail = row.createdBy.split(" - ");
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>{userDetail[0]}</TableCell>
                    <TableCell>{userDetail[1]}</TableCell>
                    <TableCell>{NairaFormatter(row.amount)}</TableCell>
                    <TableCell>{row.channel.toUpperCase()}</TableCell>
                    <TableCell>{DateOnlyFormatter(row.dateCreated)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
});
