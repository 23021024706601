import { Box, Divider } from '@mui/material'
import { tokens } from '../../theme'
import { useNavigate } from 'react-router-dom'

interface ItemProps {
  title: string
  to: string
}

export default function Footer() {
  const colors = tokens()
  const navigate = useNavigate()

  const Item = ({ title, to }: ItemProps) => {
    const handleClick = () => {
      navigate(to)
    }
    return (
      <p
        style={{
          cursor: 'pointer',
          fontSize: '1rem'
        }}
        onClick={handleClick}
      >
        {title}
      </p>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '5rem',
        backgroundColor: `${colors.blue[300]}`,
        py: 3,
        px: 5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1,
          color: '#fff'
        }}
      >
        <Box>
          <img alt='logo' src={`../../assets/logo-white.png`} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center'
          }}
        >
          <Item title='About' to='/about-us' />
          <Item title='FAQ' to='/' />
          {/* <Item title="Help" to="/" /> */}
          <a
            href='mailto:info@logisfi.com'
            style={{
              textDecoration: 'none',
              fontSize: '1rem',
              color: colors.grey[100]
            }}
          >
            Contact us
          </a>
          {/* <Item title="Privacy Policy" to="/" /> */}
        </Box>
      </Box>

      <Divider
        sx={{
          mt: 3,
          mb: 1,
          backgroundColor: `#888`
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#ddd'
        }}
      >
        <Box
          sx={{
            fontSize: '0.8rem'
          }}
        >
          &copy; 2024 LOGISFI. All rights reserved.
        </Box>
        <Box
          sx={{
            fontSize: '0.7rem'
          }}
        >
          Developed by The Haulage Hub Ltd.
        </Box>
      </Box>
    </Box>
  )
}
