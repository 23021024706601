import { Box } from "@mui/material";
import Header from "../../components/shared/header/Header";
import HorizontalTabs from "../../components/shared/horizontal-tab";
import { observer } from "mobx-react-lite";
import ExecutorSharedView from "../shared/ExecutorSharedView";
import PendingWithdrawals from "../../components/executor/payments/PendingWithdrawals";
import SettledWithdrawals from "../../components/executor/payments/SettledWithdrawals";

export default observer(function Withdrawals() {
  return (
    <ExecutorSharedView
      mainContent={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Withdrawal Manager" />
          </Box>

          <HorizontalTabs
            tabNames={["pending", "settled"]}
            tabContents={[<PendingWithdrawals />, <SettledWithdrawals />]}
          />
        </Box>
      }
    />
  );
});
