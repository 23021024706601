import { observer } from "mobx-react-lite";
import { Box, Button, MobileStepper, useTheme } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../shared/forms/MyFormikController";
import { useStore } from "../../api/main/appStore";
import { useNavigate } from "react-router-dom";
import { CreateOrUpdateFinancierModel } from "../../api/models/financier";
import { useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

interface Props {
  referralCode?: string;
}

export default observer(function CreateFinancierForm({ referralCode }: Props) {
  const { financierStore, commonStore } = useStore();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 1;
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    contactEmail: "",
    contactPhone: "",
    bvn: "",
    referralCode,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Name is required"),
    contactEmail: Yup.string()
      .required("Contact email is required")
      .email("Must be a valid email"),
    contactPhone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Must contain only digits")
      .max(13, "Phone number cannot exceed 13 digits"),
    bvn: Yup.string()
      .required("BVN is required")
      .matches(/^[0-9]+$/, "Must contain only digits")
      .length(11, "Must be 11 digit long"),
  });

  const steps = [
    {
      description: (
        <>
          <MyFormikController
            control="input"
            label="First Name"
            placeholder="Enter your first name"
            name="firstName"
          />
          <MyFormikController
            control="input"
            label="Last Name"
            placeholder="Enter your surname"
            name="lastName"
          />
          <MyFormikController
            control="input"
            label="Email Address"
            placeholder="Enter your email address"
            name="contactEmail"
            type="email"
          />
          <MyFormikController
            control="input"
            label="Mobile Number"
            placeholder="Enter your mobile number"
            name="contactPhone"
          />

          <MyFormikController
            control="input"
            label="BVN"
            placeholder="Enter bank verification number"
            name="bvn"
          />

          {referralCode == null ? (
            <MyFormikController
              control="input"
              label="Referrer Code"
              placeholder="Enter a referrer code"
              name="referralCode"
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const handleCreateFinancier = async (
    values: CreateOrUpdateFinancierModel
  ) => {
    let url = "";

    try {
      url = await financierStore.createFinancier(values);
    } catch (error) {
      commonStore.handleAppError(error);
    }

    if (url.length > 0) {
      navigate(url, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleCreateFinancier(values)}
      >
        {() => (
          <Form>
            {steps[activeStep].description}
            {activeStep === maxSteps - 1 ? (
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                }}
              >
                Sign Up
              </Button>
            ) : null}
          </Form>
        )}
      </Formik>

      <MobileStepper
        variant="text"
        sx={{
          mt: 2,
          backgroundColor: "inherit",
        }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            color="primary"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            color="primary"
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
});
