import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CreateOrUpdateProjectSourceModel, ProjectSourceModel } from "../models/projectSource";

export const ProjectSource = {
    GetAllAsync: () => requests.getAllPaginated<BaseModel<ProjectSourceModel[]>>("/ProjectSource/GetAllAsync"),

    GetAsync: (id: string) => requests.get<BaseModel<ProjectSourceModel>>(`/ProjectSource/GetAsync/${id}`),

    CreateAsync: (payload: CreateOrUpdateProjectSourceModel) => requests.post<BaseModel<ProjectSourceModel>>("/ProjectSource/CreateAsync", payload),

    UpdateAsync: (payload: CreateOrUpdateProjectSourceModel) => requests.put<BaseModel<ProjectSourceModel>>("/ProjectSource/UpdateAsync", payload),

    DeleteAsync: (id: string) => requests.del<BaseModel<boolean>>(`/ProjectSource/DeleteAsync/${id}`)
}