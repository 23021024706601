import React from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { StoreContext, store } from "./api/main/appStore";
import { theme } from "./theme";
import { GlobalHistory } from "./global-history";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreContext.Provider value={store}>
        <GlobalHistory />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StoreContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
);
