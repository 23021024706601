import { Typography } from "@mui/material";
import { useState, useEffect, ReactNode } from "react";
import { JsxElement } from "typescript";
import { tokens } from "../../../theme";

interface Props {
  element: ReactNode;
  intervalDuration: number;
  dimDuration: number;
}

export default function DimmingElement({
  element,
  intervalDuration,
  dimDuration,
}: Props) {
  const [opacity, setOpacity] = useState(1);
  const colors = tokens();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpacity(0);

      setTimeout(() => {
        setOpacity(1);
      }, dimDuration);
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, [intervalDuration, dimDuration]);

  return (
    <div style={{ opacity, transition: `opacity ${dimDuration}ms ease` }}>
      {element}
    </div>
  );
}
