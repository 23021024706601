import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { topBarGeneralDecision } from './helpers/general'
import './App.css'
import Landing from './pages/landing'
import ExecutorDashboard from './pages/executor/dashboard'
import ProjectSettings from './pages/executor/project-settings'
import MyModal from './components/shared/modal/MyModal'
import LoadingSpinner from './components/shared/loading-spinner'
import MyAlert from './components/shared/alert'
import { observer } from 'mobx-react-lite'
import ManageProjects from './pages/executor/manage-projects'
import ExecutorApprovals from './pages/executor/approvals'
import Payments from './pages/executor/payments'
import FinancierDashboard from './pages/financier/dashboard'
import FinancierProjects from './pages/financier/projects'
import FinancierWallet from './pages/financier/wallet'
import FinancierInvestments from './pages/financier/investments'
import ProjectDetailPage from './pages/financier/project-detail'
import ManualTransferPage from './pages/financier/manual-transfer'
import VerifyPayment from './pages/financier/verify-payment'
import Login from './pages/auth/Login'
import ForgotPassword from './pages/auth/ForgotPassword'
import ValidateOtp from './pages/auth/ValidateOtp'
import ChangePassword from './pages/auth/ChangePassword'
import CreateFinancier from './pages/auth/CreateFinancier'
import ChangeDefaultPassword from './pages/auth/ChangeDefaultPassword'
import { useEffect, useMemo, useState } from 'react'
import { useStore } from './api/main/appStore'
import Settings from './pages/financier/settings'
import Withdrawals from './pages/executor/withdrawals'
import Users from './pages/executor/users'
import Financiers from './pages/executor/financiers'
import Transfers from './pages/executor/transfers'
import AboutUsPage from './pages/landing/about-us'

export default observer(function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const [appReady, setAppReady] = useState(false)
  const { commonStore } = useStore()

  const handleAppLoaded = async () => {
    try {
      await commonStore.handleSetUser()
    } catch (error) {
      commonStore.handleAppError(error)
    } finally {
      setAppReady(true)
    }
  }

  useEffect(() => {
    ;(async () => {
      await handleAppLoaded()
    })()
  }, [])

  const memoizedResult = useMemo(() => {
    return topBarGeneralDecision(location.pathname)
  }, [location.pathname])

  return appReady ? (
    <div className='app'>
      <MyModal />
      <MyAlert />
      <LoadingSpinner />

      <main className='content'>
        {memoizedResult}
        <Routes>
          <Route index element={<Landing />} />
          <Route path='about-us' element={<AboutUsPage />} />

          <Route path='executor'>
            <Route index element={<ExecutorDashboard />} />
            <Route path='project-settings' element={<ProjectSettings />} />
            <Route path='projects' element={<ManageProjects />} />
            <Route path='approvals' element={<ExecutorApprovals />} />
            <Route path='payments' element={<Payments />} />
            <Route path='withdrawals' element={<Withdrawals />} />
            <Route path='users' element={<Users />} />
            <Route path='financiers' element={<Financiers />} />
            <Route path='direct-transfers' element={<Transfers />} />
          </Route>

          <Route path='financier'>
            <Route index element={<FinancierDashboard />} />
            <Route path='projects' element={<FinancierProjects />} />
            <Route path='projects/:id' element={<ProjectDetailPage />} />
            <Route path='wallet' element={<FinancierWallet />} />
            <Route
              path='wallet/:financierId'
              element={<ManualTransferPage />}
            />
            <Route path='investments' element={<FinancierInvestments />} />
            <Route path='settings' element={<Settings />} />
            <Route path='verify-payment' element={<VerifyPayment />} />
          </Route>

          <Route path='login' element={<Login />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='validate-otp' element={<ValidateOtp />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route
            path='change-default-password'
            element={<ChangeDefaultPassword />}
          />
          <Route path='new-financier' element={<CreateFinancier />} />
        </Routes>
      </main>
    </div>
  ) : (
    <></>
  )
})
