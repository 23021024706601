import { Avatar, Box, Paper, Typography } from "@mui/material";

interface Props {
  name: string;
  date: string;
  content: string;
}

export default function TestimonialCard({ name, date, content }: Props) {
  return (
    <Paper
      sx={{
        p: 2,
        width: "100%",
        minHeight: "5rem",
      }}
    >
      <Box>
        <img
          src="./assets/quote-mark.png"
          style={{
            width: "20px",
          }}
        />
      </Box>

      <Typography
        sx={{
          fontSize: "1rem",
          px: 2,
        }}
      >
        {content}
      </Typography>

      <Box
        sx={{
          mt: 2,
          px: 2,
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Box>
          <Avatar />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.8rem",
            }}
          >
            {date}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
