import { Button } from "@mui/material";

interface Props {
  title: string;
  actionCallback: () => void;
  color: "primary" | "success" | "error";
  disable?: boolean;
}

export function PlainOutlinedButton({
  title,
  actionCallback,
  color,
  disable = false,
}: Props) {
  return (
    <Button
      sx={{
        py: 0,
        px: 1,
        textTransform: "capitalize",
        fontSize: "11px",
      }}
      color={color}
      variant="outlined"
      onClick={actionCallback}
      disabled={disable}
    >
      {title}
    </Button>
  );
}

export function ThickFillButton({ title, actionCallback, color }: Props) {
  return (
    <Button
      sx={{
        textTransform: "capitalize",
      }}
      color={color}
      variant="contained"
      onClick={actionCallback}
    >
      {title}
    </Button>
  );
}

export function ThickFillButtonLong({ title, actionCallback, color }: Props) {
  return (
    <Button
      sx={{
        textTransform: "capitalize",
      }}
      color={color}
      variant="contained"
      fullWidth
      onClick={actionCallback}
    >
      {title}
    </Button>
  );
}
