import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  CreateOrUpdateFinancierMemberModel,
  FinancierMemberModel,
} from "../../../api/models/financier";
import { useStore } from "../../../api/main/appStore";
import MyFormikController from "../../shared/forms/MyFormikController";

interface Props {
  financier: FinancierMemberModel;
}

export default observer(function UpdateFinancierMember({ financier }: Props) {
  const { financierStore } = useStore();

  const initialValues = {
    id: financier.id,
    firstName: financier.firstName,
    lastName: financier.lastName,
    phoneNumber: financier.phoneNumber,
    photoUrl: financier.photoUrl,
    role: financier.role,
    financierId: financier.financierId,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("First name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
  });

  const handleUpdateFinancierMember = async (
    values: CreateOrUpdateFinancierMemberModel
  ) => await financierStore.updateFinancierMember(values);

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleUpdateFinancierMember(values)}
      >
        {({ dirty }) => (
          <Form>
            <Box>
              <MyFormikController
                control="input"
                label="First Name"
                placeholder="Enter your first name"
                name="firstName"
              />
              <MyFormikController
                control="input"
                label="Last Name"
                placeholder="Enter your last name"
                name="lastName"
              />
              <MyFormikController
                control="input"
                label="Phone Number"
                placeholder="Enter your mobile number"
                name="phoneNumber"
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
              disabled={!dirty}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
