import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import dayjs from "dayjs";
import { TODAY } from "../../../helpers/general";
import { useEffect } from "react";
import { NairaFormatter } from "../../../helpers/formatters";

export default observer(function CreateProject() {
  const {
    projectStore,
    projectSourceStore,
    productTypeStore,
    projectTypeStore,
    investmentTenureStore,
    commonStore,
    executorStore,
  } = useStore();

  let renderCount = 0;

  useEffect(() => {
    (async () => {
      if (renderCount > 0) {
        return;
      }
      try {
        renderCount++;
        commonStore.setLoading(true);
        if (projectSourceStore.projectSourceOptions.length === 0) {
          await projectSourceStore.getProjectSources2();
        }
        if (productTypeStore.productTypesOptions.length === 0) {
          await productTypeStore.getProductTypes2();
        }
        if (projectTypeStore.projectTypesOptions.length === 0) {
          await projectTypeStore.getProjectTypes2();
        }
        if (investmentTenureStore.investmentTenuresOptions.length === 0) {
          await investmentTenureStore.getInvestmentTenures2();
        }
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, [projectSourceStore.projectSourceOptions, productTypeStore.productTypesOptions, projectTypeStore.projectTypesOptions, investmentTenureStore.investmentTenuresOptions]);

  const initialValues = {
    projectSource: "",
    projectType: "",
    productType: "",
    totalNumOfTruck: "",
    amountPerUnit: "",
    roiPercent: "",
    tenure: "",
    startDate: dayjs(TODAY),
    endDate: dayjs(TODAY).add(30, "day"),
    executorId: executorStore.currentExecutor!.executorId,
  };

  const validationSchema = Yup.object({
    projectSource: Yup.string().required("Project source is required"),
    projectType: Yup.string().required("Project type is required"),
    productType: Yup.string().required("Product type is required"),
    totalNumOfTruck: Yup.number()
      .typeError("Must be a valid number")
      .positive("Value must be greater than zero"),
    amountPerUnit: Yup.number()
      .typeError("Must be a valid number")
      .positive("Value must be greater than zero"),
    roiPercent: Yup.number()
      .typeError("Must be a valid number")
      .positive("Value must be greater than zero")
      .moreThan(0, "Value must be between 0 and 1"),

    tenure: Yup.number()
      .typeError("Must be a valid number")
      .positive("Value must be greater than zero"),
  });

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await projectStore.createProject({
            ...values,
            startDate: values.startDate.toISOString(),
            endDate: values.endDate.toISOString(),
          });
          resetForm();
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <MyFormikController
              control="select"
              label="Project Source"
              placeholder="Select project source"
              name="projectSource"
              options={projectSourceStore.projectSourceOptions}
            />
            <MyFormikController
              control="select"
              label="Project Type"
              placeholder="Select project type"
              name="projectType"
              options={projectTypeStore.projectTypesOptions}
            />
            <MyFormikController
              control="select"
              label="Product Type"
              placeholder="Select product type"
              name="productType"
              options={productTypeStore.productTypesOptions}
            />
            <MyFormikController
              control="input"
              label="Total Trucks"
              placeholder="Enter total number of trucks"
              name="totalNumOfTruck"
            />
            <MyFormikController
              control="input"
              label={
                values.amountPerUnit
                  ? `Each unit will cost ${NairaFormatter(
                      +values.amountPerUnit
                    )}`
                  : "Amount per Unit"
              }
              placeholder="Enter amount per unit"
              name="amountPerUnit"
            />
            <MyFormikController
              control="input"
              label="Interest Rate [0 - 1]"
              placeholder="Enter ROI percent"
              name="roiPercent"
            />
            <MyFormikController
              control="select"
              label="Tenure"
              placeholder="Select investment tenure"
              name="tenure"
              options={investmentTenureStore.investmentTenuresOptions}
            />
            <MyFormikController
              control="date-picker"
              name="startDate"
              label="Start Date"
            />
            <MyFormikController
              control="date-picker"
              name="endDate"
              label="End Date"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
