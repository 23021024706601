import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../../theme";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { PlainOutlinedButton } from "../../shared/buttons";
import { ExecutorMemberModel } from "../../../api/models/executor";
import ChangeUserRole from "../users/ChangeUserRole";

interface Props {
  data: ExecutorMemberModel[];
}

const columns = ["Name", "Email", "Phone", "Role", " "];

export default observer(function ActiveUsersTable({ data }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const colors = tokens();
  const { commonStore, executorStore } = useStore();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeRoleClick = async (user: ExecutorMemberModel) =>
    commonStore.setModalContent(<ChangeUserRole user={user} />, "");

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                  fontSize: "0.8rem",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="left" style={{ maxWidth: 100 }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {`${row.firstName} ${row.lastName}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {row.phoneNumber}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {row.role}
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <PlainOutlinedButton
                        title="Modify Role"
                        actionCallback={() => handleChangeRoleClick(row)}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
});
