import { Box, Button, Divider } from '@mui/material'
import { tokens } from '../../theme'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { OtherRoutes, ROUTES, ROUTES1 } from '../../routes'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../api/main/appStore'
import { PowerSettingsNewOutlined } from '@mui/icons-material'
import { ActorTypes, StorageItems } from '../../data/staticData'

interface ItemProps {
  title: string
  to: string
  selected: string
  setSelected: Function
}

export default observer(function TopbarGeneral() {
  const location = useLocation()
  const [selected, setSelected] = useState(location.pathname)
  const colors = tokens()
  const navigate = useNavigate()
  const { commonStore } = useStore()

  useEffect(() => {
    setSelected(location.pathname)
  }, [location.pathname])

  const handleCreateAccount = () => navigate(OtherRoutes.createFinancier)

  const handleGotoDashboard =
    commonStore.getLocalStorageStringItem(
      StorageItems.ActorType,
      ActorTypes.Financier
    ) === ActorTypes.Financier
      ? ROUTES1.financier
      : ROUTES.executor

  const Item = ({ title, to, selected, setSelected }: ItemProps) => {
    const active = selected === to

    return (
      <Link
        style={{
          color: `${colors.grey[900]}`,
          borderTopLeftRadius: `${active ? '5px' : '0px'}`,
          borderBottomRightRadius: `${active ? '5px' : '0px'}`,
          marginBottom: 1,
          cursor: 'pointer',
          textDecoration: 'none',
          borderBottom: `${active ? '2px solid #005ac1' : ''}`
        }}
        to={to}
        onClick={() => {
          if (active) {
            return
          } else {
            setSelected(to)
          }
        }}
      >
        <Box
          sx={{
            fontSize: '15px',
            alignSelf: 'start'
          }}
        >
          {title}
        </Box>
      </Link>
    )
  }

  return (
    <Box
      sx={{
        position: 'sticky',
        top: '0'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#FFF',
          px: 2
        }}
      >
        <Link to={OtherRoutes.home}>
          <img alt='logo' src={`../../assets/logo-transparent.png`} />
        </Link>

        {/* ICONS */}
        <Box
          sx={{
            display: 'flex',
            gap: 3
          }}
        >
          {commonStore.isLoggedIn && (
            <Item
              title='Dashboard'
              to={handleGotoDashboard}
              selected={selected}
              setSelected={setSelected}
            />
          )}

          {/* <Item
            title="About"
            to={"/"}
            selected={selected}
            setSelected={setSelected}
          /> */}
          {commonStore.isLoggedIn ? (
            <Button
              startIcon={<PowerSettingsNewOutlined />}
              className='btn-err'
              variant='contained'
              onClick={commonStore.generalLogout}
            >
              Logout
            </Button>
          ) : (
            <>
              <Item
                title='Login'
                to={'/login'}
                selected={selected}
                setSelected={setSelected}
              />

              <Button
                className='btn'
                variant='contained'
                onClick={handleCreateAccount}
              >
                Create account
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          backgroundColor: `#fff`
        }}
      />
    </Box>
  )
})
