import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../../theme";
import { DateOnlyFormatter, NairaFormatter } from "../../../helpers/formatters";
import { observer } from "mobx-react-lite";
import { TransactionModel } from "../../../api/models/investment";

interface Props {
  data: TransactionModel[];
}

const columns = ["Transaction ID", "Description", "Amount", "Date"];

export default observer(function RedeemedInvestmentTable({ data }: Props) {
  const colors = tokens();

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell align="left">{row.transId}</TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: `${
                        row.tranType === "Debit"
                          ? colors.red[300]
                          : colors.green[500]
                      }`,
                    }}
                  >
                    {NairaFormatter(row.amount)}
                  </TableCell>
                  <TableCell align="left">
                    {DateOnlyFormatter(row.tranDate)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
