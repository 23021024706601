import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  brief: string;
  imageName: string;
}

export default function HowItWorkTemplate({ title, brief, imageName }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        px: 1,
      }}
    >
      <Box>
        <img
          src={`./assets/${imageName}.png`}
          style={{
            width: "5rem",
          }}
        />
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
          }}
        >
          {brief}
        </Typography>
      </Box>
    </Box>
  );
}
