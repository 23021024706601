import { Box } from "@mui/material";
import Header from "../../components/shared/header/Header";
import HorizontalTabs from "../../components/shared/horizontal-tab";
import { observer } from "mobx-react-lite";
import SuccessfulPayments from "../../components/executor/payments/SuccessfulPayments";
import FailedPayments from "../../components/executor/payments/FailedPayments";
import PendingPayments from "../../components/executor/payments/PendingPayments";
import ExecutorSharedView from "../shared/ExecutorSharedView";

export default observer(function Payments() {
  return (
    <ExecutorSharedView
      mainContent={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Payments Manager" />
          </Box>

          <HorizontalTabs
            tabNames={["successful", "pending", "failed"]}
            tabContents={[
              <SuccessfulPayments />,
              <PendingPayments />,
              <FailedPayments />,
            ]}
          />
        </Box>
      }
    />
  );
});
