import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  BankDetailModel,
  UpdateBankDetailModel,
} from "../../../api/models/financier";
import { useStore } from "../../../api/main/appStore";
import MyFormikController from "../../shared/forms/MyFormikController";
import { SelectOptionModel } from "../../../api/models/common";
import { BankDataModel } from "../../../api/models/payment";

interface Props {
  bankDetail: BankDetailModel;
  banks: BankDataModel[];
}

export default observer(function UpdateBankDetails({
  bankDetail,
  banks,
}: Props) {
  const { financierStore, commonStore } = useStore();

  const initialValues = {
    id: bankDetail.id,
    accountName: bankDetail.accountName,
    accountNumber: bankDetail.accountNumber,
    bankName: bankDetail.bankName,
    bankCode: bankDetail.bankCode,
  };

  const validationSchema = Yup.object({
    accountName: Yup.string().required("Bank account name is required"),
    accountNumber: Yup.string()
      .required("Bank account number is required")
      .matches(/^[0-9]+$/, "Must contain only digits")
      .length(10, "Must be 10 digit long"),
    bankCode: Yup.string().required("Bank is required"),
  });

  const handleUpdateBankDetail = async (values: UpdateBankDetailModel) => {
    try {
      const selectedBank = bankOptions.find(
        (el) => el.value === values.bankCode
      );

      if (!selectedBank) {
        throw new Error("Invalid bank selection");
      }
      const bankName = selectedBank.text;
      await financierStore.updateBankDetails(bankDetail.financierId, {
        ...values,
        bankName,
      });
    } catch (error) {
      commonStore.handleAppError(error);
    }
  };

  const bankOptions: SelectOptionModel[] = banks
    .filter((el) => el !== null)
    .map((el) => {
      return {
        text: el?.name,
        value: el?.code,
      };
    });

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleUpdateBankDetail(values)}
      >
        {({ dirty }) => (
          <Form>
            <Box>
              <MyFormikController
                control="select"
                label="Bank Name"
                placeholder="Select a bank"
                name="bankCode"
                options={bankOptions}
              />
              <MyFormikController
                control="input"
                label="Bank Account Name"
                placeholder="Enter name on your bank account"
                name="accountName"
              />
              <MyFormikController
                control="input"
                label="Account Number"
                placeholder="Enter your account number"
                name="accountNumber"
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
              disabled={!dirty}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
