import { Box, Typography } from "@mui/material";
import { tokens } from "../../../theme";

interface Props {
  status: "success" | "warning" | "error";
  text: string;
}

export default function Tag({ status, text }: Props) {
  const colors = tokens();

  let bgColor;

  switch (status) {
    case "success":
      bgColor = colors.green[300];
      break;
    case "warning":
      bgColor = colors.yellow[300];
      break;
    default:
      bgColor = colors.red[300];
      break;
  }

  return (
    <Box
      sx={{
        backgroundColor: `${bgColor}`,
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          fontSize: "11px",
          textAlign: "center",
          px: 1,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
