import { AccountType } from "../endpoints/accountTypeEndpoints";
import { Authentication } from "../endpoints/authenticationEndpoints";
import { Executor } from "../endpoints/executorEndpoints";
import { Financier } from "../endpoints/financierEndpoints";
import { Investment } from "../endpoints/investmentEndpoints";
import { InvestmentTenure } from "../endpoints/investmentTenureEndpoints";
import { manualTransfer } from "../endpoints/manualTransferEndpoints";
import { Payment } from "../endpoints/paymentEndpoints";
import { ProductType } from "../endpoints/productTypeEndpoints";
import { Project } from "../endpoints/projectEndpoints";
import { ProjectSource } from "../endpoints/projectSourceEndpoints";
import { ProjectType } from "../endpoints/projectTypeEndpoints";

const agent = {
    accountType: AccountType,
    executor: Executor,
    financier: Financier,
    investmentTenure: InvestmentTenure,
    payment: Payment,
    productType: ProductType,
    project: Project,
    projectSource: ProjectSource,
    projectType: ProjectType,
    authentication: Authentication,
    investment: Investment,
    manualTransfer: manualTransfer
};

export default agent;
