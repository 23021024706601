import { createContext, useContext } from "react";
import { CommonStore } from "../storeCollection/commonStore";
import { AccountTypeStore } from "../storeCollection/accountTypeStore";
import { AuthenticationStore } from "../storeCollection/authenticationStore";
import { ExecutorStore } from "../storeCollection/executorStore";
import { FinancierStore } from "../storeCollection/financierStore";
import { InvestmentTenureStore } from "../storeCollection/investmentTenureStore";
import { PaymentStore } from "../storeCollection/paymentStore";
import { ProductTypeStore } from "../storeCollection/productTypeStore";
import { ProjectSourceStore } from "../storeCollection/projectSourceStore";
import { ProjectStore } from "../storeCollection/projectStore";
import { ProjectTypeStore } from "../storeCollection/projectTypeStore";
import { InvestmentStore } from "../storeCollection/investmentStore";
import { ManualTransferStore } from "../storeCollection/manualTransferStore";

interface Store {
  accountTypeStore: AccountTypeStore
  authenticationStore: AuthenticationStore
  commonStore: CommonStore
  executorStore: ExecutorStore
  financierStore: FinancierStore
  investmentTenureStore: InvestmentTenureStore
  paymentStore: PaymentStore
  productTypeStore: ProductTypeStore
  projectSourceStore: ProjectSourceStore
  projectStore: ProjectStore
  projectTypeStore: ProjectTypeStore
  investmentStore: InvestmentStore,
  manualTransferStore: ManualTransferStore
}

export const store: Store = {
  accountTypeStore: new AccountTypeStore(),
  authenticationStore: new AuthenticationStore(),
  commonStore: new CommonStore(),
  executorStore: new ExecutorStore(),
  financierStore: new FinancierStore(),
  investmentTenureStore: new InvestmentTenureStore(),
  paymentStore: new PaymentStore(),
  productTypeStore: new ProductTypeStore(),
  projectSourceStore: new ProjectSourceStore(),
  projectStore: new ProjectStore(),
  projectTypeStore: new ProjectTypeStore(),
  investmentStore: new InvestmentStore(),
  manualTransferStore: new ManualTransferStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
