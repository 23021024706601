import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import SidebarFinancier from "./SidebarFinancier";
import { FinancierMemberModel } from "../../api/models/financier";
import { MenuOutlined } from "@mui/icons-material";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type Anchor = "top" | "left" | "bottom" | "right";

interface Props {
  hiddenContent: ReactJSXElement;
}

export default function MyDrawer({ hiddenContent }: Props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {hiddenContent}
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Button
          sx={{
            color: ``,
          }}
          onClick={toggleDrawer("bottom", true)}
        >
          <MenuOutlined
            sx={{
              fontSize: "2rem",
            }}
          />
        </Button>
        <SwipeableDrawer
          anchor={"bottom"}
          open={state["bottom"]}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
        >
          {list("bottom")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
