import { makeAutoObservable, runInAction } from 'mobx'
import { BankDataModel, CreateWithdrawalRequestModel, InitializeWalletFundingRequestModel, PaymentModel, PaymentPagination, WithdrawalRequestModel, WithdrawalRequestPagination } from '../models/payment'
import agent from '../main/apiAgent'
import { store } from '../main/appStore'
import { queryStringBuilder } from '../../helpers/general'
import { globalNavigate } from '../../global-history'
import { ROUTES1 } from '../../routes'

export class PaymentStore {
    banks: BankDataModel[] = []
    successPayments: PaymentModel[] = []
    pendingPayments: PaymentModel[] = []
    failedPayments: PaymentModel[] = []
    pendingWithdrawalRequests: WithdrawalRequestModel[] = []
    settledWithdrawalRequests: WithdrawalRequestModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    initializeWalletFunding = async (values: InitializeWalletFundingRequestModel) => {
        try {
            store.commonStore.setModalVisible(false)

            if (+values.amount > 1000000) {
                globalNavigate(`${ROUTES1.wallet}/${store.financierStore.currentFinancier?.financierId}`, { state: { amount: values.amount } })
                return
            }

            store.commonStore.setLoading(true)

            const result = await agent.payment.InitializeWalletFunding(values)

            window.open(result.responseDetails.authorizationUrl, "_self")

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    verifyWalletFunding = async (paymentRef: string) => {
        const result = await agent.payment.VerifyWalletFunding(paymentRef)

        return result.responseDetails
    }

    verifyPaymentByAdmin = async (paymentRef: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.payment.VerifyWalletFunding(paymentRef)

            this.getPayments({
                status: "pending",
                pageNumber: 1,
                pageSize: 100
            })

            store.commonStore.setAlertText(result.responseDetails)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    getBanks = async () => {
        const result = await agent.payment.GetBanks()

        return result.responseDetails
    }

    getPayments = async (paginate: PaymentPagination) => {
        try {
            store.commonStore.setLoading(true)
            const query = queryStringBuilder(paginate);

            const result = await agent.payment.GetPayments(query);

            runInAction(() => {
                switch (paginate.status) {
                    case "success":
                        this.successPayments = result.data.responseDetails
                        break;
                    case "pending":
                        this.pendingPayments = result.data.responseDetails
                        break;
                    case "failed":
                        this.failedPayments = result.data.responseDetails
                        break;
                    default:
                        break;
                }
            })

        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    createWithdrawal = async (value: CreateWithdrawalRequestModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.payment.CreateWithdrawalRequest(value)

            store.commonStore.setAlertText(result.responseDetails)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    approveWithdrawal = async (id: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.payment.ApproveWithdrawalRequest(id)

            this.getWithdrawalRequests({ status: "N", pageNumber: 1, pageSize: 50 })

            store.commonStore.setAlertText(result.responseDetails)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    deleteWithdrawal = async (id: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.payment.DeleteWithdrawalRequest(id)

            this.getWithdrawalRequests({ status: "N", pageNumber: 1, pageSize: 50 })

            store.commonStore.setAlertText(result.responseDetails)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    getWithdrawalRequests = async (paginate: WithdrawalRequestPagination) => {
        try {
            store.commonStore.setLoading(true)
            const query = queryStringBuilder(paginate);

            const result = await agent.payment.GetWithdrawalRequests(query);

            runInAction(() => {
                switch (paginate.status) {
                    case "N":
                        this.pendingWithdrawalRequests = result.data.responseDetails
                        break;
                    case "Y":
                        this.settledWithdrawalRequests = result.data.responseDetails
                        break;
                    default:
                        break;
                }
            })

        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }
}