import { motion } from 'framer-motion'
import React from 'react'
import ScrollAnimationWrapper from '../../pages/landing/ScrollAnimationWrapper'

export default function WhoWeAre() {
  return (
    <ScrollAnimationWrapper>
      <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.3,
          ease: 'linear'
        }}
        className='who-we-are'
      >
        <div>
          <h2>Who we are</h2>
        </div>

        <p>
          Logisfi is a product of The Haulage Hub Limited, a digitial logistics
          company that is based in Lagos Nigeria. At The Haulage Hub, we
          envision a seamlessly connected African logistics sector, powered by
          innovative financing solutions that drives growth and efficiency
          across the continent.
        </p>
      </motion.div>
    </ScrollAnimationWrapper>
  )
}
