import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import { ProjectModel } from "../../../api/models/project";
import { HeaderSecondary } from "../../shared/header/Header";

interface Props {
  project: ProjectModel;
}

export default observer(function CreateInvestment({ project }: Props) {
  const { investmentStore } = useStore();

  const initialValues = {
    projectId: project.id,
    unitToFinance: "",
  };

  const validationSchema = Yup.object({
    unitToFinance: Yup.number()
      .typeError("Enter valid digits")
      .positive("Enter positive digit")
      .required("Units to finance required"),
  });

  return (
    <Box>
      <HeaderSecondary title={`Finance Project`} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => investmentStore.createInvestment(values)}
      >
        {() => (
          <Form>
            <MyFormikController
              control="input"
              label="Unit(s) to Finance"
              placeholder="Enter how many trucks to finance"
              name="unitToFinance"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
