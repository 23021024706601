import { makeAutoObservable, runInAction } from 'mobx'
import { store } from '../main/appStore'
import agent from '../main/apiAgent'
import { queryStringBuilder } from '../../helpers/general'
import { CreateInvestmentModel, InvestmentModel, InvestmentPagination, TransactionModel, TransactionPagination } from '../models/investment'

export class InvestmentStore {
    activeInvestments: InvestmentModel[] = []
    redeemedInvestments: InvestmentModel[] = []
    transactions: TransactionModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getInvestments = async (paginate: InvestmentPagination) => {
        try {
            const query = queryStringBuilder(paginate);

            const result = await agent.investment.GetInvestments(query)

            runInAction(() => {
                if (!paginate.isRedeemed) {
                    this.activeInvestments = result.data.responseDetails
                } else {
                    this.redeemedInvestments = result.data.responseDetails
                }
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    createInvestment = async (values: CreateInvestmentModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.investment.Create(values)

            store.projectStore.getProjectById(values.projectId)

            store.commonStore.setAlertText("Investment was successful!")

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    redeemInvestment = async (investmentId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.investment.RedeemInvestment(investmentId)

            this.getInvestments({
                financierId: store.financierStore.currentFinancier!.financierId,
                isRedeemed: false,
                pageNumber: 1,
                pageSize: 100,
            })

            store.commonStore.setAlertText(result.responseDetails)

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    getTransactions = async (paginate: TransactionPagination) => {
        try {
            const query = queryStringBuilder(paginate);

            const result = await agent.investment.GetTransactions(query)

            runInAction(() => {
                this.transactions = result.data.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }
}