import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "../../../api/main/appStore";
import Header, { HeaderSecondary } from "../../shared/header/Header";
import { tokens } from "../../../theme";
import { ThickFillButton } from "../../shared/buttons";
import { NairaFormatter } from "../../../helpers/formatters";
import { CloudUploadOutlined } from "@mui/icons-material";
import { CompanyAccountModel } from "../../../api/models/manualTransfer";
import MySkeleton from "../../shared/loading-spinner/MySkeleton";

export default observer(function ManualTransfer() {
  const param = useParams();
  const location = useLocation();
  const { manualTransferStore, commonStore } = useStore();
  const colors = tokens();
  const [companyAccount, setCompanyAccount] =
    useState<CompanyAccountModel | null>(null);

  const [file, setFile] = useState(null);

  const [amount] = useState(location.state.amount);
  const [financierId] = useState(param["financierId"]);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>("");
  const [isImage, setIsImage] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    (async () => {
      const ourAccount = await manualTransferStore.getCompanyAccount();
      setCompanyAccount(ourAccount);
    })();
  }, []);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      setFileName(selectedFile["name"]);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setIsImage(
          reader.result?.toString().startsWith("data:image/") ?? false
        );
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = () => {
    if (!file) {
      commonStore.setAlertText("Please upload your receipt", true);
      return;
    }

    manualTransferStore.uploadManualTransfer({
      financierId: financierId!,
      reciept: file,
    });
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box>
        <Typography variant="h5">
          Make payment to the account details below
        </Typography>
        <Divider
          sx={{
            my: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <HeaderSecondary title="Account Name" />

        <Box>
          {companyAccount ? (
            companyAccount.accountName
          ) : (
            <MySkeleton count={1} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <HeaderSecondary title="Account Number" />

        <Box>
          {companyAccount ? (
            companyAccount.accountNumber
          ) : (
            <MySkeleton count={1} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <HeaderSecondary title="Bank Name" />

        <Box>
          {companyAccount ? companyAccount.bankName : <MySkeleton count={1} />}
        </Box>
      </Box>

      <Box
        sx={{
          mt: 1,
        }}
      >
        <HeaderSecondary title="Amount to Pay" />

        <Typography>{NairaFormatter(amount)}</Typography>
      </Box>
      <Divider
        sx={{
          my: 1,
        }}
      />

      <div
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <label>
          <input
            style={{
              display: "none",
            }}
            type="file"
            onChange={handleFileChange}
            required
            accept="image/*,.pdf"
          />
          <span
            style={{
              display: "flex",
              gap: 5,
              color: `${colors.blue[300]}`,
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <CloudUploadOutlined />
            <Typography>Upload Payment Receipt</Typography>
          </span>
        </label>

        {previewUrl &&
          (isImage ? (
            <img
              src={`${previewUrl}`}
              alt="Image Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                display: "block",
              }}
            />
          ) : (
            <Typography
              sx={{
                my: 2,
                fontWeight: "bold",
              }}
            >
              {fileName}
            </Typography>
          ))}
      </div>

      <Box
        sx={{
          mt: 1,
        }}
      >
        <ThickFillButton
          title="Submit for Verification"
          actionCallback={handleSubmit}
          color="primary"
        />
      </Box>
    </Box>
  );
});
