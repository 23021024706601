import TopbarGeneral from "../pages/shared/TopbarGeneral";
import { OtherRoutes } from "../routes";

export const TODAY = new Date().toISOString();

export const topBarGeneralDecision = (path: string) => {
  let result = <></>;
  if (
    path === "/" ||
    path === OtherRoutes.login ||
    path === OtherRoutes.forgotPassword ||
    path === OtherRoutes.validateOtp ||
    path === OtherRoutes.changePassword ||
    path === OtherRoutes.createFinancier ||
    path === OtherRoutes.changeDefaultPassword || path === OtherRoutes.aboutUs
  ) {
    result = <TopbarGeneral />;
  }
  return result;
};

export const queryStringBuilder = (query: any) => {
  const obj = query as any;

  const queryParameters = Object.entries(obj).map((el) => {
    if (el[1] !== "" && el[0] !== "id") {
      return `${el[0]}=${el[1]}`;
    } else {
      return "";
    }
  });
  return queryParameters.filter((el) => el !== "").join("&");
};
