import { Box, Typography } from "@mui/material";

interface Props {
  value: string;
  title: string;
}

export default function NumberTemplate({ value, title }: Props) {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: "0.85rem",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
