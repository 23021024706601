import { Box } from "@mui/material";
import Header from "../../shared/header/Header";
import { observer } from "mobx-react-lite";
import HorizontalTabs from "../../shared/horizontal-tab";
import ActiveInvestments from "./ActiveInvestments";
import RedeemedInvestments from "./RedeemedInvestments";

export default observer(function Investments() {
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box>
        <Header title="My Investments" />
      </Box>

      <Box
        sx={{
          mt: 3,
        }}
      >
        <HorizontalTabs
          tabNames={["Active", "Redeemed"]}
          tabContents={[<ActiveInvestments />, <RedeemedInvestments />]}
        />
      </Box>
    </Box>
  );
});
