export const ROUTES = {
  executor: '/executor',
  executorSetup: '/executor/project-settings',
  projects: '/executor/projects',
  approvals: '/executor/approvals',
  payments: '/executor/payments',
  withdrawals: '/executor/withdrawals',
  users: '/executor/users',
  financiers: '/executor/financiers',
  directTransfer: '/executor/direct-transfers'
}

export const ROUTES1 = {
  financier: '/financier',
  projects: '/financier/projects',
  wallet: '/financier/wallet',
  investments: '/financier/investments',
  verifyPayment: '/financier/verify-payment',
  settings: '/financier/settings'
}

export const OtherRoutes = {
  login: '/login',
  home: '/',
  aboutUs: '/about-us',
  forgotPassword: '/forgot-password',
  validateOtp: '/validate-otp',
  changePassword: '/change-password',
  createFinancier: '/new-financier',
  changeDefaultPassword: '/change-default-password'
}
