import {
  DashboardOutlined,
  DevicesOtherOutlined,
  Diversity3Outlined,
  DoneAllOutlined,
  KeyboardBackspaceOutlined,
  ListAltOutlined,
  PaymentOutlined,
  PaymentsOutlined,
  PeopleOutline,
  PowerSettingsNewOutlined,
  SettingsSuggestOutlined,
} from "@mui/icons-material";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useState } from "react";
import { OtherRoutes, ROUTES } from "../../routes";
import { observer } from "mobx-react-lite";
import { useStore } from "../../api/main/appStore";
import { ExecutorMemberModel } from "../../api/models/executor";

interface ItemProps {
  title: string;
  to: string;
  icon: ReactJSXElement;
  selected: string;
  setSelected: Function;
  isLogout?: boolean;
}

interface Props {
  executor: ExecutorMemberModel;
}

export default observer(function SidebarExecutor({ executor }: Props) {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);
  const { executorStore } = useStore();
  const navigate = useNavigate();
  const colors = tokens();

  const Item = ({
    title,
    to,
    icon,
    selected,
    setSelected,
    isLogout = false,
  }: ItemProps) => {
    const active = selected === to;

    const handleLogout = () => {
      executorStore.logoutExecutor();
      navigate(OtherRoutes.login, { replace: true });
    };

    return isLogout ? (
      <Box
        style={{
          display: "flex",
          gap: 1.5,
          padding: "6px 2.5px",
          backgroundColor: `${active ? "#005ac1" : "#fff"}`,
          color: `${colors.red[300]}`,
          borderTopLeftRadius: `${active ? "5px" : "0px"}`,
          borderBottomRightRadius: `${active ? "5px" : "0px"}`,
          marginBottom: 1,
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        {icon}
        <Box
          sx={{
            fontSize: "15px",
            alignSelf: "start",
          }}
        >
          {title}
        </Box>
      </Box>
    ) : (
      <Link
        style={{
          display: "flex",
          gap: 1.5,
          padding: "6px 2.5px",
          backgroundColor: `${active ? "#005ac1" : "#fff"}`,
          color: `${active ? "#fff" : colors.grey[900]}`,
          borderTopLeftRadius: `${active ? "5px" : "0px"}`,
          borderBottomRightRadius: `${active ? "5px" : "0px"}`,
          marginBottom: 1,
          cursor: "pointer",
          textDecoration: "none",
        }}
        to={to}
        onClick={() => {
          if (active) {
            return;
          } else {
            setSelected(title);
          }
        }}
      >
        {icon}
        <Box
          sx={{
            fontSize: "15px",
            alignSelf: "start",
          }}
        >
          {title}
        </Box>
      </Link>
    );
  };

  return (
    <Paper
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          alt="profile-user"
          width="60px"
          height="60px"
          src={`https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png`}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <Box textAlign="center">
        <Typography
          sx={{
            mt: 2,
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {executor.firstName} {executor.lastName}
        </Typography>
      </Box>

      <Divider
        sx={{
          mt: 1,
          display: "block",
          width: "30%",
          mx: "auto",
          borderBottomWidth: "0.2rem",
          backgroundColor: "#005ac1",
        }}
      />

      <Box
        sx={{
          mt: "4rem",
        }}
      >
        <Item
          title="Back Home"
          to="/"
          icon={
            <KeyboardBackspaceOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Overview"
          to={ROUTES.executor}
          icon={
            <DashboardOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Manage Projects"
          to={ROUTES.projects}
          icon={
            <ListAltOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Approvals"
          to={ROUTES.approvals}
          icon={
            <DoneAllOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Payments"
          to={ROUTES.payments}
          icon={
            <PaymentOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Direct Transfers"
          to={ROUTES.directTransfer}
          icon={
            <DevicesOtherOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Withdrawals"
          to={ROUTES.withdrawals}
          icon={
            <PaymentsOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Financiers"
          to={ROUTES.financiers}
          icon={
            <Diversity3Outlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Project Setup"
          to={ROUTES.executorSetup}
          icon={
            <SettingsSuggestOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Users"
          to={ROUTES.users}
          icon={
            <PeopleOutline
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Logout"
          to={OtherRoutes.login}
          isLogout={true}
          icon={
            <PowerSettingsNewOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
      </Box>
    </Paper>
  );
});
