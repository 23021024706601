import { makeAutoObservable, runInAction } from 'mobx'
import { CreateOrUpdateProjectSourceModel, ProjectSourceModel } from '../models/projectSource'
import agent from '../main/apiAgent'
import { store } from '../main/appStore'
import { SelectOptionModel } from '../models/common'

export class ProjectSourceStore {
    projectSources: ProjectSourceModel[] = []
    projectSourceOptions: SelectOptionModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getProjectSources = async () => {
        try {
            const result = await agent.projectSource.GetAllAsync()

            runInAction(() => {
                this.projectSources = result.data.responseDetails
            })
        } catch (error) {
            throw error
        }
    }
    getProjectSources2 = async () => {
        try {
            const result = await agent.projectSource.GetAllAsync()

            runInAction(() => {
                this.projectSourceOptions = result.data.responseDetails.map((el) => {
                    return {
                        text: el.name,
                        value: el.name
                    }
                })
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    createProjectSource = async (values: CreateOrUpdateProjectSourceModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)
            const result = await agent.projectSource.CreateAsync(values)

            this.getProjectSources()
            store.commonStore.setAlertText("Project source created successfully!")
            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    updateProjectSource = async (values: CreateOrUpdateProjectSourceModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.projectSource.UpdateAsync(values)

            store.commonStore.setAlertText("Project source updated successfully!")

            this.getProjectSources()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    deleteProjectSource = async (projectSourceId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.projectSource.DeleteAsync(projectSourceId)

            store.commonStore.setAlertText("Project source deleted successfully!")

            this.getProjectSources()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }
}