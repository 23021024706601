import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { useEffect } from "react";
import ConfirmedTransferTable from "../tables/ConfirmedTransferTable";

export default observer(function ConfirmedTransfer() {
  const { commonStore, manualTransferStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);

        await manualTransferStore.getManualTransfers({
          isConfirmed: true,
          pageNumber: 1,
          pageSize: 50,
        });
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  return (
    <Box>
      <ConfirmedTransferTable data={manualTransferStore.confirmedTransfers} />
    </Box>
  );
});
