import { Box } from "@mui/material";
import { tokens } from "../../theme";
import ChooseUsCard from "./ChooseUsCard";

export default function ChooseUs() {
  const colors = tokens();

  return (
    <div className="choose-us-container">
      <Box
        sx={{
          mb: 2,
        }}
      >
        <h4>
          Why Logisfi is a smart{" "}
          <span
            style={{
              color: `${colors.blue[300]}`,
              fontWeight: "bold",
            }}
          >
            choice
          </span>
        </h4>
      </Box>

      <div className="choose-us-cards">
        <div className="choose-us-cards_item">
          <ChooseUsCard
            title="Insured Peace of Mind"
            imageName="reliable-icon"
            content="Every load on Logisfi is insured against accidents and losses, offering an extra layer of safeguard for your investment, providing unparalled peace of mind"
          />
        </div>

        <div className="choose-us-cards_item">
          <ChooseUsCard
            title="diverse investment opportunities"
            imageName="experience-icon"
            content="A wide range of aggregated loads is available, each presenting a unique opportunity for healthy returns. Whether it's moving goods across cities or delivering raw materials, our platform offers a range of investment options"
          />
        </div>
      </div>

      <div className="choose-us-cards">
        <div className="choose-us-cards_item">
          <ChooseUsCard
            title="transparency and security"
            imageName="available-icon"
            content="We prioritise transparency and security on Logisfi. From load information to financial transactions, we provide the tools to make informed decisions, ensuring that your investment is protected, giving you peace of mind"
          />
        </div>
        <div className="choose-us-cards_item">
          <ChooseUsCard
            title="impactful returns with a purpose"
            imageName="offer-icon"
            content="Your investment actively supports businesses in meeting their logistical needs, contributes to the smooth functioning of the haulage ecosystem while earning impactful retuns"
          />
        </div>
      </div>
    </div>
  );
}
