import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { useEffect } from "react";
import ActiveProjectsTable from "../tables/ActiveProjectsTable";

export default observer(function ActiveProjects() {
  const { commonStore, projectStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);

        await projectStore.getActiveProjects({
          isApproved: true,
          isClosed: false,
          pageNumber: 1,
          pageSize: 100,
        });
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  return (
    <Box>
      <ActiveProjectsTable data={projectStore.activeProjects} />
    </Box>
  );
});
