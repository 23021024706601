import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { observer } from "mobx-react-lite";
import {
  DateOnlyFormatter,
  NairaFormatter,
  NameFromCreatedBy,
} from "../../../helpers/formatters";
import { InvestmentModel } from "../../../api/models/investment";
import { tokens } from "../../../theme";

interface Props {
  data: InvestmentModel[];
}

const columns = [
  "Financier",
  "Date Invested",
  "Project",
  "Maturity Value",
  "Maturity Date",
];

export default observer(function InvestmentNearMatureTable({ data }: Props) {
  const colors = tokens();

  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="left"
                  width={index === 0 ? 300 : "auto"}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {NameFromCreatedBy(row.createdBy)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {DateOnlyFormatter(row.dateInvested)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {NairaFormatter(row.maturityValue)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {DateOnlyFormatter(row.maturityDate)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
