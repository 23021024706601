import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";
import SettledWithdrawalsTable from "../tables/SettledWithdrawalsTable";

export default observer(function SettledWithdrawals() {
  const { paymentStore } = useStore();

  useEffect(() => {
    (async () => {
      await paymentStore.getWithdrawalRequests({
        status: "Y",
        pageNumber: 1,
        pageSize: 100,
      });
    })();
  }, []);

  return (
    <Box>
      <SettledWithdrawalsTable data={paymentStore.settledWithdrawalRequests} />
    </Box>
  );
});
