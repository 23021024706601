import { Box } from "@mui/material";

interface Props {
  imageName: string;
}

export default function ProjectSourceTemplate({ imageName }: Props) {
  return (
    <Box>
      <img
        src={imageName}
        style={{
          width: "5rem",
        }}
      />
    </Box>
  );
}
