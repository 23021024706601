import { makeAutoObservable, runInAction } from 'mobx'
import { CreateOrUpdateProjectTypeModel, ProjectTypeModel } from "../models/projectType";
import agent from '../main/apiAgent';
import { store } from '../main/appStore';
import { SelectOptionModel } from '../models/common';

export class ProjectTypeStore {
    projectTypes: ProjectTypeModel[] = []
    projectTypesOptions: SelectOptionModel[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getProjectTypes = async () => {
        try {
            const result = await agent.projectType.GetAllAsync()

            runInAction(() => {
                this.projectTypes = result.data.responseDetails
            })
        } catch (error) {
            throw error
        }
    }

    getProjectTypes2 = async () => {
        try {
            const result = await agent.projectType.GetAllAsync()

            runInAction(() => {
                this.projectTypesOptions = result.data.responseDetails.map((el) => {
                    return {
                        text: el.name,
                        value: el.name
                    }
                })
            })
        } catch (error) {
            throw error
        }
    }

    createProjectType = async (values: CreateOrUpdateProjectTypeModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)
            const result = await agent.projectType.CreateAsync(values)

            store.commonStore.setAlertText(result.responseMessage)
            this.getProjectTypes()

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    updateProjectType = async (values: CreateOrUpdateProjectTypeModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)
            const result = await agent.projectType.UpdateAsync(values)

            this.getProjectTypes()

            store.commonStore.setAlertText(result.responseMessage)

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    deleteProjectType = async (projectTypeId: string) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)
            const result = await agent.projectType.DeleteAsync(projectTypeId)

            this.getProjectTypes()

            return result.responseDetails
        } catch (error: any) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }
}