import { Box, Paper, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { tokens } from "../../theme";
import ChangePasswordForm from "../../components/authenticate/ChangePasswordForm";

export default observer(function ChangePassword() {
  const colors = tokens();

  return (
    <div className="login-container">
      <div className="login-container_left"></div>
      <div className="login-container_right">
        <Paper
          sx={{
            p: 5,
          }}
        >
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: `${colors.blue[300]}`,
                fontWeight: "bold",
                fontSize: "2rem",
                textAlign: "center",
              }}
            >
              Reset your password
            </Typography>
          </Box>

          <ChangePasswordForm />
        </Paper>
      </div>
    </div>
  );
});
