import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CreateProjectModel, ProjectModel } from "../models/project";

export const Project = {
    GetProjects: (query = "pageNumber=1&pageSize=100") => requests.getAllPaginated<BaseModel<ProjectModel[]>>(`/Project/GetProjects?${query}`),

    GetProject: (id: string) => requests.get<BaseModel<ProjectModel>>(`/Project/GetProject/${id}`),

    CreateProject: (payload: CreateProjectModel) => requests.post<BaseModel<ProjectModel>>("/Project/CreateProject", payload),

    ApproveProject: (projectId: string) => requests.post<BaseModel<ProjectModel>>(`/Project/ApproveProject/${projectId}`),

    DeleteProject: (projectId: string) => requests.del<BaseModel<boolean>>(`/Project/DeleteProject/${projectId}`),

    CloseProject: (projectId: string) => requests.post<BaseModel<string>>(`/Project/CloseProject/${projectId}`)
}