import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../shared/forms/MyFormikController";
import { useStore } from "../../api/main/appStore";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangePasswordModel } from "../../api/models/authentication";
import { useState } from "react";
import { ActorTypes } from "../../data/staticData";

export default observer(function ChangePasswordForm() {
  const { authenticationStore } = useStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [actor] = useState(state.actorType);
  const [email] = useState(state.email);
  const [otpCode] = useState(state.otpCode);

  const initialValues = {
    email,
    newPassword: "",
    validatedOtp: otpCode,
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required("Password is required"),
  });

  const handleChangePassword = async (values: ChangePasswordModel) => {
    let url = "";

    if (actor === ActorTypes.Financier) {
      url = await authenticationStore.changePasswordFinancier(values);
    } else if (actor === ActorTypes.Executor) {
      url = await authenticationStore.changePasswordExecutor(values);
    }

    if (url.length > 0) {
      navigate(url, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleChangePassword(values)}
      >
        {() => (
          <Form>
            <Box>
              <MyFormikController
                control="input-password"
                label="New Password"
                name="newPassword"
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Proceed
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
