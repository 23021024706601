import { Box, Paper, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import MySkeleton from "../../shared/loading-spinner/MySkeleton";
import { isMobile } from "react-device-detect";

interface Props {
  value: string | undefined;
  title: string;
}

export default function StatCard({ value, title }: Props) {
  const colors = tokens();

  return (
    <Paper
      sx={{
        p: 2,
        flexGrow: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: `${isMobile ? "0.8rem" : "1.2rem"}`,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>

      <Box
        sx={{
          color: colors.blue[300],
          fontSize: `${isMobile ? "1rem" : "1.7rem"}`,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {value ? value : <MySkeleton count={1} />}
      </Box>
    </Paper>
  );
}
