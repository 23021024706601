import { Box } from "@mui/material";
import Header from "../../components/shared/header/Header";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../api/main/appStore";
import ExecutorSharedView from "../shared/ExecutorSharedView";
import ActiveFinanciersTable from "../../components/executor/tables/ActiveFinanciersTable";

export default observer(function Financiers() {
  const { commonStore, financierStore } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);

        await financierStore.getFinanciers({
          isActive: true,
          pageNumber: 1,
          pageSize: 50,
        });
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  return (
    <ExecutorSharedView
      mainContent={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Active Financiers" />
          </Box>

          <ActiveFinanciersTable data={financierStore.financiers} />
        </Box>
      }
    />
  );
});
