import { observer } from "mobx-react-lite";
import { Box, Button, Typography } from "@mui/material";
import { useStore } from "../../../api/main/appStore";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../../shared/forms/MyFormikController";
import { HeaderSecondary } from "../../shared/header/Header";
import { ManualTransferModel } from "../../../api/models/manualTransfer";
import { NairaFormatter } from "../../../helpers/formatters";

interface Props {
  transfer: ManualTransferModel;
}

export default observer(function ConfirmTransferForm({ transfer }: Props) {
  const { manualTransferStore } = useStore();

  const initialValues = {
    amountConfirmed: "",
    transferId: transfer.id,
  };

  const validationSchema = Yup.object({
    amountConfirmed: Yup.number()
      .typeError("Enter valid digits")
      .positive("Enter positive digit")
      .required("Amount is required"),
  });

  return (
    <Box>
      <HeaderSecondary
        title={`Confirm Transfer from ${transfer.financierName.split(" ")[0]}`}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => manualTransferStore.confirmManualTransfer(values)}
      >
        {({ values }) => (
          <Form>
            <MyFormikController
              control="input"
              label="Transferred Amount"
              placeholder="Enter transferred amount"
              name="amountConfirmed"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="success"
              sx={{
                mt: 2,
              }}
            >
              Confirm {NairaFormatter(Number(values.amountConfirmed))}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
