import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";
import ActiveUsersTable from "../tables/ActiveUsersTable";

export default observer(function ActiveUsers() {
  const { executorStore } = useStore();

  useEffect(() => {
    (async () => {
      await executorStore.getExecutorMembers(
        executorStore.currentExecutor!.executorId
      );
    })();
  }, []);

  return (
    <Box>
      <ActiveUsersTable data={executorStore.executorMembers} />
    </Box>
  );
});
