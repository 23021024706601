import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../../api/main/appStore";
import FailedPaymentTable from "../tables/FailedPaymentTable";

export default observer(function FailedPayments() {
  const { paymentStore } = useStore();

  useEffect(() => {
    (async () => {
      await paymentStore.getPayments({
        status: "failed",
        pageNumber: 1,
        pageSize: 100,
      });
    })();
  }, []);

  return (
    <Box>
      <FailedPaymentTable data={paymentStore.failedPayments} />
    </Box>
  );
});
