import requests from "../main/apiConfig";
import { AuthenticationResponseModel, FinancierUserModel, LoginModel } from "../models/authentication";
import { BaseModel, ChangeDefaultPasswordModel } from "../models/common";
import { AddBankDetailModel, BankDetailModel, ChangeFinancierStateModel, CreateOrUpdateFinancierMemberModel, CreateOrUpdateFinancierModel, FinancierMemberModel, FinancierModel, FinancierOverviewModel, FinancierWalletModel, UpdateBankDetailModel, UpdateFinancierWalletModel } from "../models/financier";

export const Financier = {
    GetFinanciers: (query = "pageNumber=1&pageSize=100") => requests.getAllPaginated<BaseModel<FinancierModel[]>>(`/Financier/GetFinanciers?${query}`),

    GetFinancier: (id: string) => requests.get<BaseModel<FinancierModel>>(`/Financier/GetFinancier/${id}`),

    CreateFinancier: (payload: CreateOrUpdateFinancierModel) => requests.post<BaseModel<FinancierModel>>("/Financier/CreateFinancier", payload),

    UpdateFinancier: (payload: CreateOrUpdateFinancierModel) => requests.put<BaseModel<FinancierModel>>("/Financier/UpdateFinancier", payload),

    DeleteFinancier: (id: string) => requests.del<BaseModel<string>>(`/Financier/${id}`),

    ChangeFinacierState: (payload: ChangeFinancierStateModel) => requests.post<BaseModel<ChangeFinancierStateModel>>("/Financier/ChangeFinancierState", payload),

    AddBankDetail: (payload: AddBankDetailModel) => requests.post<BaseModel<BankDetailModel>>("/Financier/AddBankDetail", payload),

    GetBankDetails: (financierId: string) => requests.get<BaseModel<BankDetailModel[]>>(`/Financier/${financierId}/GetBankDetails`),

    DeleteBankDetail: (bankDetailId: string) => requests.del<BaseModel<boolean>>(`/Financier/DeleteBankDetail/${bankDetailId}`),

    UpdateFinancierWallet: (payload: UpdateFinancierWalletModel) => requests.put<BaseModel<FinancierWalletModel>>("/Financier/UpdateFinancierWallet", payload),

    GetFinancierWallet: (financierId: string) => requests.get<BaseModel<FinancierWalletModel>>(`/Financier/GetFinancierWallet/${financierId}`),

    GetFinancierMembers: () => requests.getAllPaginated<BaseModel<FinancierMemberModel[]>>("/FinancierMember/GetFinancierMembers"),

    GetFinancierMemberById: (id: string) => requests.get<BaseModel<FinancierMemberModel>>(`/FinancierMember/GetFinancierMemberById/${id}`),

    CreateFinancierMember: (payload: CreateOrUpdateFinancierMemberModel) => requests.post<BaseModel<FinancierMemberModel>>("/FinancierMember/CreateFinancierMember", payload),

    UpdateFinancierMember: (payload: CreateOrUpdateFinancierMemberModel) => requests.put<BaseModel<FinancierMemberModel>>("/FinancierMember/UpdateFinancierMember", payload),

    ChangeDefaultPassword: (payload: ChangeDefaultPasswordModel) => requests.post<BaseModel<FinancierMemberModel>>("/FinancierMember/ChangeDefaultPassword", payload),

    Login: (payload: LoginModel) => requests.post<BaseModel<AuthenticationResponseModel<FinancierUserModel>>>("/Authentication/FinancierLogin", payload),

    GetFinancierOverview: (financierId: string) => requests.get<BaseModel<FinancierOverviewModel>>(`/Financier/GetFinancierOverview/${financierId}`),

    UpdateProfilePicture: (payload: FormData) => requests.post<BaseModel<string>>("/FinancierMember/UpdateProfilePicture", payload),

    UpdateBankDetail: (payload: UpdateBankDetailModel, financierId: string) => requests.post<BaseModel<string>>(`/Financier/UpdateBankDetail/${financierId}`, payload)
}