import { Box, Grid, Paper } from "@mui/material";
import SidebarFinancier from "./SidebarFinancier";
import { isMobile } from "react-device-detect";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { observer } from "mobx-react-lite";
import { useStore } from "../../api/main/appStore";
import { useNavigate } from "react-router-dom";
import { OtherRoutes } from "../../routes";
import MyDrawer from "./MyDrawer";

interface Props {
  mainContent: ReactJSXElement;
}

export default observer(function FinancierSharedView({ mainContent }: Props) {
  const { financierStore } = useStore();
  const navigate = useNavigate();

  const financier = financierStore.currentFinancier;

  if (!financier) {
    navigate(OtherRoutes.home, { replace: true });
    return <></>;
  }

  return (
    <div>
      <div>
        <img
          alt="logo"
          src={`../../assets/financier-frame.png`}
          style={{
            backgroundColor: "#fff",
            width: "100%",
            height: "4rem",
          }}
        />
      </div>

      {isMobile ? (
        <Grid
          container
          sx={{
            height: "100vh",
            px: 1,
            mt: "-3rem",
            mb: "2rem",
          }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                overflow: "auto",
                height: "100vh",
              }}
            >
              <MyDrawer
                hiddenContent={<SidebarFinancier financier={financier} />}
              />
              {mainContent}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            height: "100vh",
            px: 5,
            mt: "-3rem",
            mb: "2rem",
          }}
        >
          <Grid item xs={3}>
            <Box
              sx={{
                overflow: "auto",
                height: "100vh",
              }}
            >
              <SidebarFinancier financier={financier} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Paper
              sx={{
                overflow: "auto",
                height: "100vh",
              }}
            >
              {mainContent}
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
});
