import { CopyAll } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useField } from "formik";
import { MyInputProps } from "./CustomInput";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";

export default observer(function CustomCopyInput(props: MyInputProps) {
  const { name, label, ...rest } = props;
  const [field, meta] = useField(props);

  const { commonStore } = useStore();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(props.valueToCopy!);
    window.scrollTo(0, 0);
    commonStore.setAlertText(props.copyResponse!);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        mb: 1,
      }}
    >
      <TextField
        id={name}
        type="text"
        label={label}
        autoComplete="off"
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleCopyClick}
                onMouseDown={handleMouseDownPassword}
              >
                <CopyAll />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          sx: {
            p: "4px",
          },
        }}
        error={meta.touched && meta.error ? true : false}
        color="primary"
        variant="standard"
        {...field}
      />
      {meta.touched && meta.error ? (
        <FormHelperText
          sx={{
            color: "red",
            p: 0,
            ml: 0,
          }}
        >
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
});
