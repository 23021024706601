import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../shared/forms/MyFormikController";
import { useStore } from "../../api/main/appStore";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { LoginModel } from "../../api/models/authentication";
import { ActorTypes } from "../../data/staticData";
import { OtherRoutes } from "../../routes";

export default observer(function ExecutorLoginForm() {
  const { executorStore } = useStore();
  const colors = tokens();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),

    password: Yup.string().required("Password is required"),
  });

  const handleExecutorLogin = async (values: LoginModel) => {
    const url = await executorStore.loginExecutor(values);

    if (url.length > 0) {
      navigate(url, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        px: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleExecutorLogin(values)}
      >
        {() => (
          <Form>
            <MyFormikController control="input" label="Email" name="email" />

            <Box>
              <MyFormikController
                control="input-password"
                label="Password"
                name="password"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                my: 0.5,
              }}
            >
              <Link
                to={OtherRoutes.forgotPassword}
                state={{ actorType: ActorTypes.Executor }}
                style={{
                  textDecoration: "none",
                  color: `${colors.blue[300]}`,
                }}
              >
                Forgot Password ?
              </Link>
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 0,
              }}
            >
              login
            </Button>
            {/* <Box
              sx={{
                fontSize: "0.8rem",
                textAlign: "center",
                mt: 3,
              }}
            >
              Don't have an account ?{" "}
              <Link
                to={"/"}
                style={{
                  textDecoration: "none",
                  color: `${colors.blue[300]}`,
                }}
              >
                Signup
              </Link>
            </Box> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
});
