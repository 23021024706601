import { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../api/main/appStore";
import Header from "../../components/shared/header/Header";
import CustomAccordion from "../../components/shared/custom-accordion";
import ProductTypeTable from "../../components/executor/tables/ProductTypeTable";
import CreateOrUpdateProductType from "../../components/executor/productTypes/CreateOrUpdateProductType";
import CreateOrUpdateProjectSource from "../../components/executor/projectSources/CreateOrUpdateProjectSource";
import ProjectSourceTable from "../../components/executor/tables/ProjectSourceTable";
import CreateOrUpdateProjectType from "../../components/executor/projectTypes/CreateOrUpdateProjectType";
import ProjectTypeTable from "../../components/executor/tables/ProjectTypeTable";
import CreateOrUpdateInvestmentTenure from "../../components/executor/investmentTenures/CreateOrUpdateInvestmentTenure";
import InvestmentTenureTable from "../../components/executor/tables/InvestmentTenureTable";
import ExecutorSharedView from "../shared/ExecutorSharedView";

export default observer(function ProjectSettings() {
  const {
    productTypeStore,
    commonStore,
    projectSourceStore,
    projectTypeStore,
    investmentTenureStore,
  } = useStore();

  useEffect(() => {
    (async () => {
      try {
        commonStore.setLoading(true);
        await projectSourceStore.getProjectSources();
        await productTypeStore.getProductTypes();
        await projectTypeStore.getProjectTypes();
        await investmentTenureStore.getInvestmentTenures();
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  const handleCreateProjectSourceClick = () =>
    commonStore.setModalContent(
      <CreateOrUpdateProjectSource projectSource={null} />,
      ""
    );

  const handleCreateProductTypeClick = () =>
    commonStore.setModalContent(
      <CreateOrUpdateProductType productType={null} />,
      ""
    );

  const handleCreateProjectTypeClick = () =>
    commonStore.setModalContent(
      <CreateOrUpdateProjectType projectType={null} />,
      ""
    );

  const handleCreateInvestmentTenureClick = () =>
    commonStore.setModalContent(
      <CreateOrUpdateInvestmentTenure investmentTenure={null} />,
      ""
    );

  return (
    <ExecutorSharedView
      mainContent={
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Project Settings" />
          </Box>

          <CustomAccordion
            title="Project Sources"
            isExpanded={true}
            content={
              <>
                <Box
                  sx={{
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography></Typography>

                  <Button
                    className="btn"
                    variant="contained"
                    onClick={handleCreateProjectSourceClick}
                  >
                    Create New
                  </Button>
                </Box>
                <ProjectSourceTable data={projectSourceStore.projectSources} />
              </>
            }
          />

          <CustomAccordion
            title="Product Types"
            isExpanded={false}
            content={
              <>
                <Box
                  sx={{
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography></Typography>

                  <Button
                    className="btn"
                    variant="contained"
                    onClick={handleCreateProductTypeClick}
                  >
                    Create New
                  </Button>
                </Box>
                <ProductTypeTable data={productTypeStore.productTypes} />
              </>
            }
          />

          <CustomAccordion
            title="Project Types"
            isExpanded={false}
            content={
              <>
                <Box
                  sx={{
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography></Typography>

                  <Button
                    className="btn"
                    variant="contained"
                    onClick={handleCreateProjectTypeClick}
                  >
                    Create New
                  </Button>
                </Box>
                <ProjectTypeTable data={projectTypeStore.projectTypes} />
              </>
            }
          />

          <CustomAccordion
            title="Investment Tenures"
            isExpanded={false}
            content={
              <>
                <Box
                  sx={{
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography></Typography>

                  <Button
                    className="btn"
                    variant="contained"
                    onClick={handleCreateInvestmentTenureClick}
                  >
                    Create New
                  </Button>
                </Box>
                <InvestmentTenureTable
                  data={investmentTenureStore.investmentTenures}
                />
              </>
            }
          />
        </Box>
      }
    />
  );
});
