import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CompanyAccountModel, ConfirmManualTransferModel, ManualTransferModel } from "../models/manualTransfer";

export const manualTransfer = {
    UploadManualTransfer: (payload: FormData) => requests.post<BaseModel<string>>("/ManualTransfer/UploadManualTransfer", payload),

    GetAllManualTransfer: (query = "pageNumber=1&pageSize=100") => requests.getAllPaginated<BaseModel<ManualTransferModel[]>>(`/ManualTransfer/GetAllManualTransfer?${query}`),

    ConfirmManualTransfer: (payload: ConfirmManualTransferModel) => requests.post<BaseModel<string>>("/ManualTransfer/ConfirmManualTransfer", payload),

    DeleteManualTransfer: (transferId: string) => requests.del<BaseModel<string>>(`/ManualTransfer/DeleteManualTransfer?transferId=${transferId}`),

    GetCompanyAccount: () => requests.get<BaseModel<CompanyAccountModel>>("/ManualTransfer/CompanyAccount")
}