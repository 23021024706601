import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CreateOrUpdateProductTypeModel, ProductTypeModel } from "../models/productType";

export const ProductType = {
    GetAllAsync: () => requests.getAllPaginated<BaseModel<ProductTypeModel[]>>("/ProductType/GetAllAsync"),

    GetAsync: (id: string) => requests.get<BaseModel<ProductTypeModel>>(`/ProductType/GetAsync/${id}`),

    CreateAsync: (payload: CreateOrUpdateProductTypeModel) => requests.post<BaseModel<ProductTypeModel>>("/ProductType/CreateAsync", payload),

    UpdateAsync: (payload: CreateOrUpdateProductTypeModel) => requests.put<BaseModel<ProductTypeModel>>("/ProductType/UpdateAsync", payload),

    DeleteAsync: (id: string) => requests.del<BaseModel<boolean>>(`/ProductType/DeleteAsync/${id}`)
}