import requests from "../main/apiConfig";
import { AuthenticationResponseModel, ChangePasswordModel, ExecutorUserModel, FinancierUserModel, ForgotPasswordModel, LoginModel, OtpModel } from "../models/authentication";
import { BaseModel } from "../models/common";

export const Authentication = {
    ExecutorLogin: (payload: LoginModel) => requests.post<BaseModel<AuthenticationResponseModel<ExecutorUserModel>>>("/Authentication/ExecutorLogin", payload),

    FinancierLogin: (payload: LoginModel) => requests.post<BaseModel<AuthenticationResponseModel<FinancierUserModel>>>("/Authentication/FinancierLogin", payload),

    ValidateOtp: (otp: string) => requests.post<BaseModel<OtpModel>>(`/Authentication/ValidateOtp/${otp}`),

    FinancierForgotPassword: (payload: ForgotPasswordModel) => requests.post<BaseModel<string>>("/Authentication/Financier/ForgotPassword", payload),

    ExecutorForgotPassword: (payload: ForgotPasswordModel) => requests.post<BaseModel<string>>("/Authentication/Executor/ForgotPassword", payload),

    ChangeFinancierPassword: (payload: ChangePasswordModel) => requests.post<BaseModel<AuthenticationResponseModel<FinancierUserModel>>>("/FinancierMember/ChangePassword", payload),

    ChangeExecutorPassword: (payload: ChangePasswordModel) => requests.post<BaseModel<AuthenticationResponseModel<ExecutorUserModel>>>("/ExecutorMember/ChangePassword", payload)
}