import { Avatar, Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../api/main/appStore";
import { tokens } from "../../../theme";
import CustomAccordion from "../../shared/custom-accordion";
import UpdateFinancierMember from "../profile/UpdateFinancierMember";
import BankDetail from "../profile/BankDetail";
import { BankDataModel } from "../../../api/models/payment";
import UpdateBankDetails from "../profile/UpdateBankDetails";
import MySkeleton from "../../shared/loading-spinner/MySkeleton";
import Refer from "../profile/Refer";

export default observer(function FinancierSetting() {
  const { commonStore, financierStore, paymentStore } = useStore();
  const [file, setFile] = useState<File | string>("");
  const financier = financierStore.currentFinancier!;
  const [imageUrl, setImageUrl] = useState(financier.photoUrl);
  const colors = tokens();
  const [banks, setBanks] = useState<BankDataModel[]>([]);

  useEffect(() => {
    setImageUrl(financier.photoUrl);
    (async () => {
      try {
        commonStore.setLoading(true);
        const bankData = await paymentStore.getBanks();
        setBanks(bankData);
        if (!financierStore.bankDetails.length) {
          await financierStore.getBankDetails(financier.financierId);
        }
      } catch (error) {
        commonStore.handleAppError(error);
      } finally {
        commonStore.setLoading(false);
      }
    })();
  }, []);

  const handleFileUpload = (file: File | undefined) => {
    if (!file) {
      return;
    }
    setImageUrl(URL.createObjectURL(file));
    setFile(file);
  };

  const handleRemove = () => {
    setFile("");
    setImageUrl(financier.photoUrl);
  };

  const handleSave = async () => {
    const url = await financierStore.updateProfilePicture(file);
    setFile("");
    setImageUrl(url);
  };

  const handleReset = async () => {
    const url = await financierStore.updateProfilePicture("");
    setFile("");
    setImageUrl(url);
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={imageUrl}
            sx={{
              width: "7rem",
              height: "7rem",
              backgroundSize: "cover",
              backgroundPosition: "top center",
              mb: 1,
            }}
          />

          {file ? (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button size="small" color="success" onClick={handleSave}>
                Save
              </Button>
              <Button size="small" color="error" onClick={handleRemove}>
                remove
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                fontSize: "0.8rem",
                gap: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label>
                <input
                  style={{
                    display: "none",
                  }}
                  type="file"
                  onChange={(e) => handleFileUpload(e.target?.files?.[0])}
                  required
                  accept="image/*"
                />
                <Typography
                  sx={{
                    borderRadius: 1,
                    cursor: "pointer",
                    color: `${colors.blue[300]}`,
                  }}
                >
                  Edit
                </Typography>
              </label>
              <Button
                sx={{
                  borderRadius: 1,
                  cursor: "pointer",
                  color: `${colors.grey[300]}`,
                  textTransform: "capitalize",
                }}
                size="small"
                onClick={handleReset}
              >
                Reset
              </Button>
            </Box>
          )}
        </Box>
        <Box textAlign="center">
          <Typography
            sx={{
              mt: 2,
              fontWeight: "bold",
              fontSize: "1.4rem",
            }}
          >
            {financier.firstName} {financier.lastName}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          p: 3,
        }}
      >
        <CustomAccordion
          title="Profile"
          isExpanded={true}
          content={<UpdateFinancierMember financier={financier} />}
        />

        <CustomAccordion
          title="Refer And Earn"
          isExpanded={true}
          content={<Refer financier={financier} />}
        />

        <CustomAccordion
          title="Bank Details"
          isExpanded={true}
          content={
            financierStore.bankDetails.length > 0 ? (
              <UpdateBankDetails
                banks={banks}
                bankDetail={financierStore.bankDetails[0]}
              />
            ) : (
              <MySkeleton count={1} />
            )
          }
        />
      </Box>
    </Box>
  );
});
