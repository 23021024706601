import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { CreateOrUpdateProjectTypeModel, ProjectTypeModel } from "../models/projectType";

export const ProjectType = {
    GetAllAsync: () => requests.getAllPaginated<BaseModel<ProjectTypeModel[]>>("/ProjectType/GetAllAsync"),

    GetAsync: (id: string) => requests.get<BaseModel<ProjectTypeModel>>(`/ProjectType/GetAsync/${id}`),

    CreateAsync: (payload: CreateOrUpdateProjectTypeModel) => requests.post<BaseModel<ProjectTypeModel>>("/ProjectType/CreateAsync", payload),

    UpdateAsync: (payload: CreateOrUpdateProjectTypeModel) => requests.put<BaseModel<ProjectTypeModel>>("/ProjectType/UpdateAsync", payload),

    DeleteAsync: (id: string) => requests.del<BaseModel<boolean>>(`/ProjectType/DeleteAsync/${id}`)
}