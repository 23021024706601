import { makeAutoObservable, runInAction } from 'mobx';
import { ChangeExecutorStateModel, CreateOrUpdateExecutorMemberModel, CreateOrUpdateExecutorModel, ExecutorMemberModel, ExecutorModel, ExecutorOverviewModel, HomeDataModel } from '../models/executor';
import agent from '../main/apiAgent';
import { store } from '../main/appStore';
import { ChangeDefaultPasswordModel, SelectOptionModel } from '../models/common';
import { ExecutorUserModel, LoginModel } from '../models/authentication';
import { ActorTypes, StorageItems } from '../../data/staticData';
import { OtherRoutes, ROUTES } from '../../routes';

export class ExecutorStore {
    executors: ExecutorModel[] = []
    executorMembers: ExecutorMemberModel[] = []
    currentExecutor: ExecutorUserModel | null = null
    overviewData: ExecutorOverviewModel | null = null
    homeData: HomeDataModel | null = null
    roleOptions: SelectOptionModel[] = [{
        text: "Member",
        value: "Member"
    }, {
        text: "Admin",
        value: "Admin"
    }]

    constructor() {
        makeAutoObservable(this)
    }

    getExecutorOverviewData = async () => {
        const data = await agent.executor.GetExecutorOverview();

        runInAction(() => {
            this.overviewData = data.responseDetails
        })
    }

    getHomeData = async () => {
        const data = await agent.executor.GetHomeData();

        runInAction(() => {
            this.homeData = data.responseDetails
        })
    }

    getExecutors = async () => {
        try {
            store.commonStore.setLoading(true)

            const result = await agent.executor.GetExecutors()

            runInAction(() => {
                this.executors = result.data.responseDetails
            })
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    getExecutorById = async (executorId: string) => {
        try {
            const result = await agent.executor.GetExecutorById(executorId)

            return result.responseDetails
        } catch (error) {
            throw error
        }
    }

    createExecutor = async (values: CreateOrUpdateExecutorModel) => {
        try {
            const result = await agent.executor.CreateExecutor(values)

            store.commonStore.setAlertText(`${values.name} created successfully!`)

            return result.responseDetails
        } catch (error) {
            throw error
        }
        finally {
            store.commonStore.setModalVisible(false)
        }
    }

    updateExecutor = async (values: CreateOrUpdateExecutorModel) => {
        try {
            const result = await agent.executor.UpdateExecutor(values)

            store.commonStore.setAlertText(`${values.name} updated successfully!`)

            return result.responseDetails
        } catch (error) {
            throw error
        }
        finally {
            store.commonStore.setModalVisible(false)
        }
    }

    changeExecutorState = async (values: ChangeExecutorStateModel) => {
        try {
            const result = await agent.executor.ChangeExecutorState(values)

            if (result.responseDetails.isActive) {
                store.commonStore.setAlertText("Executor activation successful!")
            }

            return result.responseDetails
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    getExecutorMembers = async (executorId: string) => {
        try {
            store.commonStore.setLoading(true)

            const result = await agent.executor.GetExecutorMembers(executorId)

            runInAction(() => {
                this.executorMembers = result.data.responseDetails
            })
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    createExecutorMember = async (values: CreateOrUpdateExecutorMemberModel) => {
        try {
            store.commonStore.setLoading(true)
            const result = await agent.executor.CreateExecutorMember(values)

            store.commonStore.setAlertText(`User added successfully`)
            this.getExecutorMembers(values.executorId)
            return result.responseDetails
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setLoading(false)
            store.commonStore.setModalVisible(false)
        }
    }

    updateExecutorMember = async (values: CreateOrUpdateExecutorMemberModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.executor.UpdateExecutorMember(values)

            store.commonStore.setAlertText(`User updated successfully`)
            this.getExecutorMembers(values.executorId)

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    changeDefaultPassword = async (values: ChangeDefaultPasswordModel) => {
        let location = ""
        try {
            store.commonStore.setLoading(true)
            const result = await agent.executor.ChangeDefaultPassword(values)

            if (result.responseDetails) {
                location = OtherRoutes.login
            }

            store.commonStore.setAlertText("All is set now! Password change was successful")
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }

    loginExecutor = async (values: LoginModel): Promise<string> => {
        let location = "";
        try {
            localStorage.clear()
            store.commonStore.setLoading(true)
            const result = await agent.executor.Login(values)

            const details = result.responseDetails

            runInAction(() => {
                this.currentExecutor = details.user
                store.commonStore.setToken(details.token)
                localStorage.setItem(StorageItems.ExecutorMemberId, details.user.id)
                localStorage.setItem(StorageItems.Token, details.token)
                localStorage.setItem(StorageItems.ActorType, ActorTypes.Executor)
                store.commonStore.setIsLoggedIn(true)
            })
            location = ROUTES.executor
        } catch (error: any) {
            let data = {}

            if (error.response.status === 412) {
                data = { email: values.email, defaultPassword: values.password, actorType: ActorTypes.Executor }
            }
            store.commonStore.handleAppError(error, data)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }

    logoutExecutor = () => {
        localStorage.clear()
        this.currentExecutor = null
        store.commonStore.setToken(null)
        store.commonStore.setIsLoggedIn(false)
    }
}