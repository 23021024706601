import { makeAutoObservable, runInAction } from 'mobx'
import { AddBankDetailModel, BankDetailModel, ChangeFinancierStateModel, CreateOrUpdateFinancierMemberModel, CreateOrUpdateFinancierModel, FinancierMemberModel, FinancierModel, FinancierOverviewModel, FinancierPagination, FinancierWalletModel, UpdateBankDetailModel, UpdateFinancierWalletModel } from '../models/financier'
import { store } from '../main/appStore'
import agent from '../main/apiAgent'
import { ChangeDefaultPasswordModel } from '../models/common'
import { queryStringBuilder } from '../../helpers/general'
import { FinancierUserModel, LoginModel } from '../models/authentication'
import { OtherRoutes, ROUTES1 } from '../../routes'
import { ActorTypes, StorageItems } from '../../data/staticData'

export class FinancierStore {
    financiers: FinancierModel[] = []
    financierMembers: FinancierMemberModel[] = []
    pendingFinanciers: FinancierModel[] = []
    myWallet: FinancierWalletModel | null = null
    currentFinancier: FinancierUserModel | null = null
    bankDetails: BankDetailModel[] = []
    overviewData: FinancierOverviewModel | null = null

    constructor() {
        makeAutoObservable(this)
    }

    getFinanciers = async (paginate: FinancierPagination) => {
        const query = queryStringBuilder(paginate);

        const result = await agent.financier.GetFinanciers(query)

        runInAction(() => {
            if (query.includes("isActive") && paginate.isActive === false) {
                this.pendingFinanciers = result.data.responseDetails
            } else {
                this.financiers = result.data.responseDetails
            }
        })
    }

    getFinancierMemberById = async (id: string) => {
        try {
            store.commonStore.setLoading(true)

            const user = await agent.financier.GetFinancierMemberById(id)

            runInAction(() => {
                this.currentFinancier = user.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    createFinancier = async (values: CreateOrUpdateFinancierModel) => {
        let location = ""
        try {
            store.commonStore.setLoading(true)
            const result = await agent.financier.CreateFinancier(values)

            if (result.responseDetails) {
                store.commonStore.setAlertText("Your account creation request was successful and pending approval")
            }

            location = OtherRoutes.home
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }

    updateFinancier = async (values: CreateOrUpdateFinancierModel) => {
        try {
            const result = await agent.financier.UpdateFinancier(values)

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    changeFinancierState = async (values: ChangeFinancierStateModel) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.financier.ChangeFinacierState(values)

            store.commonStore.setAlertText("Financier approved successfully!")

            this.getFinanciers({
                isActive: false,
                pageNumber: 1,
                pageSize: 60,
            });

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    deleteFinancier = async (id: string, status: boolean) => {
        try {
            store.commonStore.setModalVisible(false)
            store.commonStore.setLoading(true)

            const result = await agent.financier.DeleteFinancier(id)

            store.commonStore.setAlertText(result.responseDetails)

            this.getFinanciers({
                isActive: status,
                pageNumber: 1,
                pageSize: 60,
            });

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    addBankDetail = async (values: AddBankDetailModel) => {
        try {
            const result = await agent.financier.AddBankDetail(values)

            store.commonStore.setAlertText("Bank detail added successfully!")

            return result.responseDetails
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    getBankDetails = async (financierId: string) => {
        const result = await agent.financier.GetBankDetails(financierId)

        runInAction(() => {
            this.bankDetails = result.responseDetails
        })
        return result.responseDetails
    }

    updateBankDetails = async (financierId: string, values: UpdateBankDetailModel) => {
        try {
            store.commonStore.setLoading(true)

            const response = await agent.financier.UpdateBankDetail(values, financierId);
            store.commonStore.setAlertText(response.responseDetails)

            this.getBankDetails(financierId)
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    updateFinancierWallet = async (values: UpdateFinancierWalletModel) => {
        try {
            const result = await agent.financier.UpdateFinancierWallet(values)

            return result.responseDetails
        } catch (error) {
            store.commonStore.handleAppError(error);
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    getFinancierWallet = async (financierId: string) => {
        try {
            const result = await agent.financier.GetFinancierWallet(financierId)

            runInAction(() => {
                this.myWallet = result.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        }
    }

    getFinancierMembers = async () => {
        try {
            store.commonStore.setLoading(false)
            const result = await agent.financier.GetFinancierMembers()

            runInAction(() => {
                this.financierMembers = result.data.responseDetails
            })
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    createFinancierMember = async (values: CreateOrUpdateFinancierMemberModel) => {
        try {
            const result = await agent.financier.CreateFinancierMember(values)

            store.commonStore.setAlertText("Member added successfully")

            return result.responseDetails
        } catch (error) {
            throw error
        } finally {
            store.commonStore.setModalVisible(false)
        }
    }

    updateFinancierMember = async (values: CreateOrUpdateFinancierMemberModel) => {
        try {
            store.commonStore.setLoading(true)
            const result = await agent.financier.UpdateFinancierMember(values)

            store.commonStore.setAlertText("Profile update was successful!")

            runInAction(() => {
                this.currentFinancier = result.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
    }

    changeDefaultPassword = async (values: ChangeDefaultPasswordModel) => {
        let location = ""
        try {
            store.commonStore.setLoading(true)
            const result = await agent.financier.ChangeDefaultPassword(values)

            if (result.responseDetails) {
                location = OtherRoutes.login
            }

            store.commonStore.setAlertText("All is set now! Password change was successful")
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }

    loginFinancier = async (values: LoginModel): Promise<string> => {
        let location = "";
        try {
            localStorage.clear()
            store.commonStore.setLoading(true)
            const result = await agent.financier.Login(values)

            const details = result.responseDetails

            runInAction(() => {
                this.currentFinancier = details.user
                store.commonStore.setToken(details.token)
                localStorage.setItem(StorageItems.FinancerMemberId, details.user.id)
                localStorage.setItem(StorageItems.Token, details.token)
                localStorage.setItem(StorageItems.ActorType, ActorTypes.Financier)
                store.commonStore.setIsLoggedIn(true)
            })
            location = ROUTES1.financier
        } catch (error: any) {
            let data = {}

            if (error.response.status === 412) {
                data = { email: values.email, defaultPassword: values.password, actorType: ActorTypes.Financier }
            }
            store.commonStore.handleAppError(error, data)
        } finally {
            store.commonStore.setLoading(false)
        }
        return location
    }

    logoutFinancier = () => {
        localStorage.clear()
        this.currentFinancier = null
        store.commonStore.setToken(null)
        store.commonStore.setIsLoggedIn(false)
    }

    getFinancierOverviewData = async () => {
        if (!this.currentFinancier) {
            return
        }
        const data = await agent.financier.GetFinancierOverview(this.currentFinancier.financierId);

        runInAction(() => {
            this.overviewData = data.responseDetails
        })
    }

    updateProfilePicture = async (img: File | string) => {
        let imgUrl = this.currentFinancier!.photoUrl
        try {
            store.commonStore.setLoading(true)

            const formData = new FormData();
            formData.append("file", img);

            const result = await agent.financier.UpdateProfilePicture(formData)
            imgUrl = result.responseDetails

            const user = await agent.financier.GetFinancierMemberById(this.currentFinancier?.id!)

            runInAction(() => {
                this.currentFinancier = user.responseDetails
            })
        } catch (error) {
            store.commonStore.handleAppError(error)
        } finally {
            store.commonStore.setLoading(false)
        }
        return imgUrl
    }
}