import { createTheme } from "@mui/material/styles";



// color design tokens export
export const tokens = () => ({
    ...{
        //Grey
        grey: {
            100: "#e0e0e0",
            200: "#c2c2c2",
            300: "#a3a3a3",
            400: "#858585",
            500: "#666666",
            600: "#525252",
            700: "#3d3d3d",
            800: "#292929",
            900: "#1B1B1F",
        },
        //Blue
        blue: {
            100: "#ccd0e3",
            200: "#99a1c8",
            300: "#005ac1", // From Figma
            400: "#334391",
            500: "#001475",
            600: "#00105e",
            700: "#000c46",
            800: "#00082f",
            900: "#000417"
        },

        //Green
        green: {
            100: "#f7fff8",
            200: "#f0fff1",
            300: "#D9FFDD", // From Figma
            400: "#e1ffe4",
            500: "#00B152", //From Figma
            600: "#aeccb1",
            700: "#829985",
            800: "#576658",
            900: "#2b332c"
        },

        //Yellow
        yellow: {
            100: "#fffaed",
            200: "#fff6da",
            300: "#FFE8A3", // From Figma
            400: "#ffedb5",
            500: "#EDB900", // From Figma
            600: "#ccba82",
            700: "#998b62",
            800: "#665d41",
            900: "#332e21"
        },

        //Red
        red: {
            100: "#f1d1d1",
            200: "#e3a3a3",
            300: "#BA1A1A", // From Figma
            400: "#c84848",
            500: "#ba1a1a",
            600: "#951515",
            700: "#701010",
            800: "#4a0a0a",
            900: "#250505"
        }
    }
});

// mui theme settings
export const themeSettings = () => {
    const colors = tokens();
    return {
        palette: {
            ...{
                // palette values for light mode
                primary: {
                    main: colors.blue[300],
                },
                secondary: {
                    main: colors.green[500],
                },
                neutral: {
                    dark: colors.grey[700],
                    main: colors.grey[500],
                    // light: colors.grey[100],
                },
                error: {
                    main: colors.red[500]
                },
                background: {
                    default: "#d3d3d3",
                },
            },
            typography: {
                fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                fontSize: 12,
                h1: {
                    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                    fontSize: 40,
                },
                h2: {
                    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                    fontSize: 32,
                },
                h3: {
                    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                    fontSize: 24,
                },
                h4: {
                    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                    fontSize: 16,
                },
                h5: {
                    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                    fontSize: 16,
                },
                h6: {
                    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
                    fontSize: 14,
                },
            },
        }
    };
}

export const theme = createTheme(themeSettings());
