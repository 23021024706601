import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../shared/forms/MyFormikController";
import { useStore } from "../../api/main/appStore";
import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { ForgotPasswordModel } from "../../api/models/authentication";
import { useState } from "react";
import { ActorTypes } from "../../data/staticData";

export default observer(function ForgotPasswordForm() {
  const { authenticationStore } = useStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [actor] = useState(state.actorType);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
  });

  const handleForgotPassword = async (values: ForgotPasswordModel) => {
    let url = "";
    let actorType = "";

    if (actor === ActorTypes.Financier) {
      url = await authenticationStore.forgotPasswordFinancier(values);
      actorType = ActorTypes.Financier;
    } else if (actor === ActorTypes.Executor) {
      url = await authenticationStore.forgotPasswordExecutor(values);
      actorType = ActorTypes.Executor;
    }

    if (url.length > 0) {
      navigate(url, { replace: true, state: { actorType } });
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleForgotPassword(values)}
      >
        {() => (
          <Form>
            <MyFormikController
              control="input"
              label="Email"
              name="email"
              placeholder="Enter your email address"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Proceed
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
