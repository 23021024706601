import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { ProjectModel } from "../../api/models/project";
import { tokens } from "../../theme";
import { OtherRoutes, ROUTES1 } from "../../routes";
import {
  DateOnlyFormatter,
  DecimalToPercent,
  NairaFormatter,
} from "../../helpers/formatters";
import { PlainOutlinedButton } from "../shared/buttons";
import Tag from "../shared/tag/Tag";
import { useStore } from "../../api/main/appStore";

interface Props {
  data: ProjectModel[];
}

const columns = [
  "Type",
  "Product",
  "Amount",
  "Rate",
  "Closing",
  "Status",
  "Action",
];

export default observer(function LiveProjectsTable({ data }: Props) {
  const colors = tokens();
  const navigate = useNavigate();
  const { commonStore, financierStore, executorStore } = useStore();

  const handleViewProjectClick = (project: ProjectModel) => {
    if (commonStore.isLoggedIn && financierStore.currentFinancier) {
      navigate(`${ROUTES1.projects}/${project.id}`);
    } else {
      navigate(OtherRoutes.createFinancier);
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="center" width={"auto"}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {row.projectType}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {row.productType}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {NairaFormatter(row.amountPerUnit)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {DecimalToPercent(row.roiPercent)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {DateOnlyFormatter(row.endDate)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Tag text="Active" status="success" />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <PlainOutlinedButton
                      title="View"
                      actionCallback={() => handleViewProjectClick(row)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
