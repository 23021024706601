import { observer } from "mobx-react-lite";
import { Box, Button } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import MyFormikController from "../shared/forms/MyFormikController";
import { useStore } from "../../api/main/appStore";
import { useLocation, useNavigate } from "react-router-dom";
import { OtherRoutes } from "../../routes";
import { useState } from "react";

export default observer(function ValidateOtpForm() {
  const { authenticationStore } = useStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [actorType] = useState(state.actorType);

  const initialValues = {
    otp: "",
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("Otp is required"),
  });

  const handleValidateOtp = async (value: string) => {
    const otpResponse = await authenticationStore.validateOtp(value);

    if (otpResponse) {
      navigate(OtherRoutes.changePassword, {
        replace: true,
        state: {
          otpCode: otpResponse.code,
          email: otpResponse.email,
          actorType,
        },
      });
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleValidateOtp(values.otp)}
      >
        {() => (
          <Form>
            <MyFormikController
              control="input"
              label="OTP"
              name="otp"
              placeholder="Enter OTP sent to you"
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
              }}
            >
              Proceed
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});
