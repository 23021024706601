import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

interface ScrollAnimationWrapperProps {
  children: React.ReactNode
}

const ScrollAnimationWrapper: React.FC<ScrollAnimationWrapperProps> = ({
  children
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.1 // Adjust the threshold as needed
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    } else {
      controls.start('hidden')
    }
  }, [controls, inView])

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={controls}
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  )
}

export default ScrollAnimationWrapper
