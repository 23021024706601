import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import PendingPaymentTable from "../tables/PendingPaymentTable";
import { useStore } from "../../../api/main/appStore";
import { useEffect } from "react";

export default observer(function PendingPayments() {
  const { paymentStore } = useStore();

  useEffect(() => {
    (async () => {
      await paymentStore.getPayments({
        status: "pending",
        pageNumber: 1,
        pageSize: 100,
      });
    })();
  }, []);

  return (
    <Box>
      <PendingPaymentTable data={paymentStore.pendingPayments} />
    </Box>
  );
});
