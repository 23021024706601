import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../../theme";
import {
  DateOnlyFormatter,
  DecimalToPercent,
  NairaFormatter,
} from "../../../helpers/formatters";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../api/main/appStore";
import { ProjectModel } from "../../../api/models/project";
import ModalDecisionContent from "../../shared/modal/ModalDecisionContent";
import { Button } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";

interface Props {
  data: ProjectModel[];
}

const columns = [
  "Source",
  "Total Units",
  "Units Left",
  "Rate",
  "Financed",
  "Tenure",
  "Start Date",
  "Closing Date",
  " ",
];

export default observer(function ActiveProjectsTable({ data }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const colors = tokens();

  const { commonStore, projectStore } = useStore();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseProjectClick = (project: ProjectModel) =>
    commonStore.setModalContent(
      <ModalDecisionContent
        actionName={`close a project from ${project.projectSource}`}
        actionCallback={() => projectStore.closeProject(project.id)}
      />,
      ""
    );

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: `${colors.grey[600]}`,
                  fontWeight: "bolder",
                },
              }}
            >
              {columns.map((column, index) => (
                <TableCell key={index} align="left" style={{ maxWidth: 100 }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const amountFinanced = row.totalAmount - row.amountLeft;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>{row.projectSource}</TableCell>
                    <TableCell>{row.totalNumOfTruck}</TableCell>
                    <TableCell>{row.numOfTruckLeft}</TableCell>
                    <TableCell>{DecimalToPercent(row.roiPercent)}</TableCell>
                    <TableCell>{NairaFormatter(amountFinanced)}</TableCell>
                    <TableCell>{row.tenure} days</TableCell>
                    <TableCell>{DateOnlyFormatter(row.startDate)}</TableCell>
                    <TableCell>{DateOnlyFormatter(row.endDate)}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        color="error"
                        startIcon={<LockOutlined />}
                        onClick={() => handleCloseProjectClick(row)}
                      >
                        Close
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
});
