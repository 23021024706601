import {
  AccountBalanceWalletOutlined,
  DashboardOutlined,
  KeyboardBackspaceOutlined,
  LibraryBooksOutlined,
  PowerSettingsNewOutlined,
  SettingsOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useState } from "react";
import { OtherRoutes, ROUTES1 } from "../../routes";
import { observer } from "mobx-react-lite";
import { useStore } from "../../api/main/appStore";
import { FinancierMemberModel } from "../../api/models/financier";

interface ItemProps {
  title: string;
  to: string;
  icon: ReactJSXElement;
  selected: string;
  setSelected: Function;
  isLogout?: boolean;
}

interface Props {
  financier: FinancierMemberModel;
}

export default observer(function SidebarFinancier({ financier }: Props) {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);
  const { financierStore } = useStore();
  const navigate = useNavigate();
  const colors = tokens();

  const Item = ({
    title,
    to,
    icon,
    selected,
    setSelected,
    isLogout = false,
  }: ItemProps) => {
    const active = selected === to;

    const handleLogout = () => {
      financierStore.logoutFinancier();
      navigate(OtherRoutes.login, { replace: true });
    };

    return isLogout ? (
      <Box
        style={{
          display: "flex",
          gap: 1.5,
          padding: "6px 2.5px",
          backgroundColor: `${active ? "#005ac1" : "#fff"}`,
          color: `${colors.red[300]}`,
          borderTopLeftRadius: `${active ? "5px" : "0px"}`,
          borderBottomRightRadius: `${active ? "5px" : "0px"}`,
          marginBottom: 1,
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        {icon}
        <Box
          sx={{
            fontSize: "15px",
            alignSelf: "start",
          }}
        >
          {title}
        </Box>
      </Box>
    ) : (
      <Link
        style={{
          display: "flex",
          gap: 1.5,
          padding: "6px 2.5px",
          backgroundColor: `${active ? "#005ac1" : "#fff"}`,
          color: `${active ? "#fff" : colors.grey[900]}`,
          borderTopLeftRadius: `${active ? "5px" : "0px"}`,
          borderBottomRightRadius: `${active ? "5px" : "0px"}`,
          marginBottom: 1,
          cursor: "pointer",
          textDecoration: "none",
        }}
        to={to}
        onClick={() => {
          if (active) {
            return;
          } else {
            setSelected(title);
          }
        }}
      >
        {icon}
        <Box
          sx={{
            fontSize: "15px",
            alignSelf: "start",
          }}
        >
          {title}
        </Box>
      </Link>
    );
  };

  return (
    <Paper
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          alt="profile-user"
          width="60px"
          height="60px"
          src={financier.photoUrl}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <Box textAlign="center">
        <Typography
          sx={{
            mt: 2,
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {financier.firstName} {financier.lastName}
        </Typography>
      </Box>

      <Divider
        sx={{
          mt: 1,
          display: "block",
          width: "30%",
          mx: "auto",
          borderBottomWidth: "0.2rem",
          backgroundColor: "#005ac1",
        }}
      />

      <Box
        sx={{
          mt: "4rem",
        }}
      >
        <Item
          title="Back Home"
          to="/"
          icon={
            <KeyboardBackspaceOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Overview"
          to={ROUTES1.financier}
          icon={
            <DashboardOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Projects"
          to={ROUTES1.projects}
          icon={
            <LibraryBooksOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Wallet"
          to={ROUTES1.wallet}
          icon={
            <AccountBalanceWalletOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="My Investments"
          to={ROUTES1.investments}
          icon={
            <WorkOutlineOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Profile & Bank Details"
          to={ROUTES1.settings}
          icon={
            <SettingsOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Logout"
          to={OtherRoutes.login}
          isLogout={true}
          icon={
            <PowerSettingsNewOutlined
              sx={{
                fontSize: "16px",
                alignSelf: "center",
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
      </Box>
    </Paper>
  );
});
