import requests from "../main/apiConfig";
import { BaseModel } from "../models/common";
import { BankDataModel, CreateWithdrawalRequestModel, InitializeWalletFundingRequestModel, InitializeWalletFundingResponseModel, PaymentModel, WithdrawalRequestModel } from "../models/payment";

export const Payment = {
    InitializeWalletFunding: (payload: InitializeWalletFundingRequestModel) => requests.post<BaseModel<InitializeWalletFundingResponseModel>>("/Payment/InitializeWalletFunding", payload),

    VerifyWalletFunding: (paymentRef: string) => requests.get<BaseModel<string>>(`/Payment/VerifyWalletFunding/${paymentRef}`),

    GetBanks: () => requests.get<BaseModel<BankDataModel[]>>("/Payment/GetBanks"),

    GetPayments: (query: string) => requests.getAllPaginated<BaseModel<PaymentModel[]>>(`/Payment/GetPayments?${query}`),

    CreateWithdrawalRequest: (payload: CreateWithdrawalRequestModel) => requests.post<BaseModel<string>>(`/Payment/CreateWithdrawalRequest`, payload),

    ApproveWithdrawalRequest: (requestId: string) => requests.post<BaseModel<string>>(`/Payment/ApproveWithdrawalRequest/${requestId}`),

    DeleteWithdrawalRequest: (requestId: string) => requests.del<BaseModel<string>>(`/Payment/DeleteWithdrawalRequest/${requestId}`),

    GetWithdrawalRequests: (query: string) => requests.getAllPaginated<BaseModel<WithdrawalRequestModel[]>>(`/Payment/GetWithdrawalRequests?${query}`)
}